import React from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import { Text, Box, Image } from "@chakra-ui/react";
import GetFinnieModal from "../modals";
import footerImage from "../../img/koii_light.png";

const HomeComponent = ({ handleConnectFinnie, connectButtonText, total }) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        gap="10px"
        alignItems="center"
        padding={{ base: "1rem 1rem 0 1rem", md: "2rem 2rem 0 2rem" }}
      >
        <Link
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "10px",
            alignItems: "center",
          }}
          to="https://moti.bio"
        >
          <img
            src="/images/DarkThemeLogo.png"
            alt="main logo"
            style={{ height: "40px" }}
          />
          <Text
            fontFamily="Poppins"
            fontSize="2rem"
            fontWeight="600"
            color="#FFFFFF"
          >
            MOTI.BIO
          </Text>
        </Link>
      </Box>
      <div className="Home">
        <div className="psuedoBackground"></div>
        <div className="container public-key-input-container connect-container">
          <div className="auth-user">
            <Box
              display="flex"
              flexDirection={{ base: "column", md: "row" }}
              alignItems="center"
              marginTop={50}
              justifyContent="center"
              minHeight="500px"
            >
              <Box
                id="animated-image-container"
                marginRight={{ base: "0px", md: "100px" }}
              >
                <Image
                  width={{ base: "100%", md: "19.2rem" }}
                  objectFit={"contain"}
                  id="animated-image-frame"
                  alt="frame"
                />
              </Box>
              <Box
                marginTop={{ base: "50px", md: "0px" }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  height: "100%",
                }}
              >
                <Text
                  fontSize={{ base: "2rem", md: "4rem" }}
                  textAlign={{ base: "center", md: "left" }}
                  maxWidth="650px"
                  fontFamily="Poppins"
                  fontWeight="600"
                  background="linear-gradient(270.49deg, #FEA973 0.17%, #F476B7 50.01%, #9E6CF5 96.95%)"
                  style={{
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                  marginTop={"-20px"}
                  lineHeight={{ base: "2.8rem", md: "5.2rem" }}
                >
                  Your Passport To Decentralized Social Web
                </Text>

                <Text
                  my="20px"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                  textAlign={{ base: "center", md: "left" }}
                  maxWidth={{ base: "auto", md: "600px" }}
                  width="100%"
                  color="#FFFF"
                  fontFamily="Poppins"
                  fontWeight="500"
                >
                  Dive into a new era where your identity, privacy & connections
                  are entirely in your hands
                </Text>
                <GetFinnieModal />
              </Box>
            </Box>
          </div>
        </div>
      </div>

      <Box as="footer">
        <Image
          src={footerImage}
          alt="Footer Image"
          objectFit="contain"
          boxSize={{ base: "150px", md: "200px" }}
        />
      </Box>
    </>
  );
};

export default HomeComponent;
