import React, { useState } from "react";
import {
  Box,
  Input,
  Text,
  Divider,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import { array, object, string, mixed, boolean } from "yup";
import "../../css/ButtonAnimations.css";
import { PersonalizeLinktree } from "./personalize-linktree";
import { useWalletContext } from "../../contexts";
import { CheckCircle2 } from "lucide-react";
import { useSelector } from "react-redux";
import { errorPopup, infoPopup } from "./toastCreatePage";
// for the wallet dropdown
import WalletSelectionModal from "./walletVerifications/customWalletModal";
// for the wallet verification
import { metaMask } from "./walletVerifications/metaMask";
import { Solana } from "./walletVerifications/solana";
import { finnieWallet } from "./walletVerifications/finni";
import { Bitcoin } from "./walletVerifications/bitcoin";
// for the extension
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import { CoinbaseWalletAdapter } from "@solana/wallet-adapter-coinbase";
// components for the forms
import ProfileLink from "./profileLink/profile-link";
import ProfileImage from "./profileImage/profile-image";
import MySocials from "./mySocials/my-socials";
import MyLinks from "./myLinks/my-links";
import BindWallets from "./bindWallets/bind-wallets";
import EmailWalletsAddress from "./email&WalletAddress/email-walletsAddress";
import PublishOrUpdateButton from "./customButton/publish-update-button";

// for the button color based on the theme
export const buttonBgColors = {
  Dark: "linear-gradient(94.11deg, hsl(314, 0%, 25%), hsl(214, 0%, 45%),  hsl(314, 0%, 25%))",
  Mint: "#100E1E",
  Gradient:
    "linear-gradient(94.11deg, hsl(214, 100%, 35%), hsl(214, 100%, 55%), hsl(214, 100%, 35%));",
  "Gradient-Two": "#734C3D",
};

function LinktreeForm({
  choosenLabelTheme,
  choosenTheme,
  linksGroup,
  mySocials,
  myWallets,
  image,
  handleSubmit,
  setFiles,
  setImage,
  setImageName,
  handleChangeUserName,
  usernameError,
  isLoading,
  isUploadingImage,
  handleLabelSelection,
  handleThemeSelection,
  colorScheme,
  registerLinkText,
  isValidUrl,
  referralCode,
  setReferralCode,
  referralUsernameError,
}) {
  const toast = useToast();
  const { magicPayload } = useWalletContext();
  const user = useSelector((state) => state.user?.user);

  // for the wallets
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [selectedWalletIndex, setSelectedWalletIndex] = useState("");
  const handleClick = async (index, setFieldValue) => {
    const walletLabel = myWallets[index].label;
    setSelectedWalletIndex(index);
    setIsOpen(true);
  };
  const deleteWalletAddress = (index, setFieldValue) => {
    setFieldValue(`myWallets.${index}.address`, "");
    setFieldValue(`myWallets.${index}.signature`, "");
    setFieldValue(`myWallets.${index}.key`, "");
  };
  // for the solana/Ethereum verification
  const handleWalletVerify = async (setFieldValue) => {
    const cryptoName = myWallets[selectedWalletIndex].label;
    switch (selectedWallet) {
      case "phantom":
        if (cryptoName === "Ethereum (ETH)") {
          await metaMask(
            myWallets,
            selectedWalletIndex,
            setFieldValue,
            toast,
            "phantom",
            PhantomWalletAdapter
          );
        } else if (cryptoName === "Solana (SOL)") {
          Solana(
            toast,
            PhantomWalletAdapter,
            myWallets,
            selectedWalletIndex,
            setFieldValue
          );
        } else if (cryptoName === "Bitcoin (BTC)") {
          await Bitcoin(
            toast,
            PhantomWalletAdapter,
            myWallets,
            selectedWalletIndex,
            setFieldValue
          );
        }
        break;
      case "solflare":
        Solana(
          toast,
          SolflareWalletAdapter,
          myWallets,
          selectedWalletIndex,
          setFieldValue
        );
        break;
      case "coinbase":
        if (cryptoName === "Ethereum (ETH)") {
          await metaMask(
            myWallets,
            selectedWalletIndex,
            setFieldValue,
            toast,
            "coinbase",
            CoinbaseWalletAdapter
          );
        } else if (cryptoName === "Solana (SOL)") {
          await Solana(
            toast,
            CoinbaseWalletAdapter,
            myWallets,
            selectedWalletIndex,
            setFieldValue
          );
        }
        break;
      case "metaMask":
        await metaMask(
          myWallets,
          selectedWalletIndex,
          setFieldValue,
          toast,
          "metaMask",
          ""
        );
        break;
      case "finnie":
        await finnieWallet(
          myWallets,
          selectedWalletIndex,
          setFieldValue,
          toast
        );
        break;
      default:
        errorPopup(toast, "Please select a wallet.");
        break;
    }
    handleClose();
  };

  return (
    <>
      <Formik
        // initialValues of the form
        initialValues={{
          name: "",
          description: "",
          image: null,
          background: "",
          links: [linksGroup],
          linktreeAddress: "",
          mySocials: mySocials,
          myWallets: myWallets,
        }}
        // for creating validate schema
        validationSchema={object({
          name: string().required("Name is required"),
          description: string()
            .min(5, "Bio is too short!")
            .max(140, "Bio should be less than 140 characters.")
            .required("A bio is required"),
          linktreeAddress: string()
            .min(5, "Address is too short!")
            .max(200, "Address is too Long")
            .required("An address is required.")
            .matches(
              /^[\w-]+$/,
              "Invalid characters. Only letters, numbers, underscores, and dashes are allowed."
            ),
          image: mixed().nullable().optional(),
          links: array(
            object({
              label: string().required("Link label is required"),
              redirectUrl: string()
                .required("Link URL is required")
                .matches(/^(https?:\/\/)/i, "Enter correct url!"),
              key: string(),
              isFavorite: boolean(),
            })
          )
            .min(1, "At least one link is required!")
            .required("Add a social link"),
          mySocials: array(
            object({
              label: string(),
              content: string().when("label", (label, field) => {
                if (label[0] === "Linkedln") {
                  return string().matches(
                    /^(https?:\/\/)?(?:www\.)?linkedin\.com\/(in|company)\/[\w-]+$/i,
                    `Please enter a valid ${label[0]} URL`
                  );
                } else if (label[0] == "Facebook") {
                  return string().matches(
                    /^(https?:\/\/)?(www\.)?facebook\.com\/.+$/i,
                    `Please enter a valid ${label[0]} URL`
                  );
                } else if (label[0] == "Email") {
                  return string().matches(
                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i,
                    `Please enter a valid ${label[0]} address`
                  );
                } else if (label[0] === "Whatsapp") {
                  return string().matches(
                    /^\+[\d]+[\s]*[\d]+$/,
                    `Please enter a valid Phone number.`
                  );
                } else if (label[0] === "Telegram") {
                  return string().matches(
                    /@[a-zA-Z0-9_.]{1,30}/,
                    `Please enter a valid ${label[0]} username.`
                  );
                } else if (label[0] === "Discord") {
                  return string().matches(
                    /^https:\/\/discord(app)?\.com\/users\/(\d{17,19})$/,
                    `Please enter a valid ${label[0]} url.`
                  );
                } else {
                  return string().matches(
                    /@[a-zA-Z0-9_.]{1,30}/,
                    `Please enter a valid ${label[0]} handle.`
                  );
                }
              }),
            })
          ),
          myWallets: array(
            object({
              label: string(),
              address: string(),
              signature: string(),
              key: string(),
            })
          ),
        })}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleSubmit,
          isValid,
          errors,
          setFieldValue,
          touched,
          setFieldError,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              {/* profile link username */}
              <ProfileLink
                setFieldValue={setFieldValue}
                isValidUrl={isValidUrl}
                handleChangeUserName={handleChangeUserName}
                usernameError={usernameError}
                errors={errors}
                values={values}
                page={true}
              />

              <Divider my={5} color="#C5C5C5" borderWidth="1px" />

              {/* for the referrals */}
              <Box width="100%">
                {/* referral main heading */}
                <Text
                  fontSize="0.9rem"
                  fontWeight="500"
                  fontFamily="Poppins"
                  color="var(--koii-create-topic)"
                >
                  Add a Referrer
                </Text>
                {/* text below referral */}
                <Text
                  fontSize="0.8rem"
                  fontWeight="500"
                  mb={1}
                  fontFamily="Poppins"
                  color="#6B6B72"
                >
                  If you are invited by a MOTI user, enter their username here.
                  You can also leave this blank.
                </Text>
                {/* referral */}
                <Box my={2} width={{ base: "100%" }}>
                  <Field
                    name="ref"
                    border="1px solid var(--koii-input-border-color)"
                  >
                    {({ field }) => (
                      <>
                        <InputGroup>
                          <InputLeftAddon
                            {...field}
                            name="ref"
                            backgroundColor="var(--koii-input-background-color)"
                            color="var(--koii-create-topic)"
                            height="3rem"
                            border={
                              errors.referralCode
                                ? "2px solid #E53E3E"
                                : "1px solid var(--koii-input-border-color)"
                            }
                          >
                            moti.bio/
                          </InputLeftAddon>
                          <Input
                            errorBorderColor="red.500"
                            value={referralCode}
                            onChange={(e) => {
                              const newValue = e.target.value.trim();
                              setReferralCode(newValue);
                            }}
                            onKeyUp={(e) => {
                              handleChangeUserName(e, "referralUsername");
                            }}
                            isInvalid={errors && !!errors.referralCode}
                            style={{
                              backgroundColor:
                                "var(--koii-input-background-color)",
                              fontFamily: "Poppins",
                              paddingLeft: "0 !important",
                              color: "var(--koii-create-topic)",
                            }}
                            border="1px solid var(--koii-input-border-color)"
                            type="text"
                            placeholder="Referral MOTI username"
                            height="3rem"
                          />
                          {!referralUsernameError && (
                            <InputRightElement mt={1}>
                              <CheckCircle2 fill="#00BA00" color="white" />
                            </InputRightElement>
                          )}
                        </InputGroup>
                        {!!referralUsernameError && (
                          <Text
                            fontFamily="Poppins"
                            mt={0}
                            fontWeight="500"
                            className="error"
                          >
                            {referralUsernameError}
                          </Text>
                        )}
                      </>
                    )}
                  </Field>
                </Box>
              </Box>

              {/* hr line */}
              <Divider my={5} color="#C5C5C5" borderWidth="1px" />

              {/* profile picture and name and description */}
              <ProfileImage
                errors={errors}
                image={image}
                choosenTheme={choosenTheme}
                setFiles={setFiles}
                setImage={setImage}
                setImageName={setImageName}
                page={true}
              />

              {/* mySocials */}
              <MySocials
                setFieldValue={setFieldValue}
                errors={errors}
                values={values}
              />

              <Divider my={5} color="#C5C5C5" borderWidth="1px" />

              {/* myLinks */}
              <MyLinks
                setFieldValue={setFieldValue}
                errors={errors}
                values={values}
                linksGroup={linksGroup}
              />

              {/* hr line */}
              <Divider my={5} color="#C5C5C5" borderWidth="1px" />

              {/* Bind Wallets */}
              <BindWallets
                setFieldValue={setFieldValue}
                values={values}
                myWallets={myWallets}
                handleClick={handleClick}
                deleteWalletAddress={deleteWalletAddress}
                buttonBgColors={buttonBgColors}
                choosenTheme={choosenTheme}
              />

              {/* hr line */}
              <Divider my={5} color="#C5C5C5" borderWidth="1px" />

              {/* select the theme */}
              <PersonalizeLinktree
                colorScheme={colorScheme}
                choosenTheme={choosenTheme}
                handleThemeSelection={handleThemeSelection}
                handleLabelSelection={handleLabelSelection}
                values={values}
                choosenLabelTheme={choosenLabelTheme}
              />

              {/* publish button */}
              <PublishOrUpdateButton
                buttonBgColors={buttonBgColors}
                choosenTheme={choosenTheme}
                magicPayload={magicPayload}
                isUploadingImage={isUploadingImage}
                isSubmitting={isSubmitting}
                isLoading={isLoading}
                buttonText={registerLinkText}
              />

              {/* email and the wallet address */}
              <EmailWalletsAddress user={user} />

              {/* custom verification modal */}
              <WalletSelectionModal
                isOpen={isOpen}
                onClose={handleClose}
                selectedWallet={selectedWallet}
                setSelectedWallet={setSelectedWallet}
                handleWalletVerify={() => handleWalletVerify(setFieldValue)}
                selectedWalletIndex={selectedWalletIndex}
                myWallets={myWallets}
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
}

export default LinktreeForm;
