import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  useDisclosure,
  Box,
  Image,
  Spinner,
} from "@chakra-ui/react";
import Login from "./magic/Login";
import { useWalletContext } from "../../contexts";
import { UserContext } from "../../contexts/userContext";
import { X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

function GetFinnieModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { magicData, userData, setUserData } = useWalletContext();
  const { isLoggedIn, isLoading } = useContext(UserContext);
  const [hasLinkTree, setHasLinkTree] = useState();
  const [linkTree, setLinkTree] = useState();
  const [, setPublicKey] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [btnText, setBtnText] = useState("Loading ...");
  const [isChecking, setIsChecking] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const user = useSelector((state) => state.user);
  const [state, setState] = useState(0);

  useEffect(() => {
    document.title = "MOTI.BIO | Login";
    setTimeout(() => {
      document.documentElement.setAttribute("data-theme", "light");
    }, 500);
  }, []);

  const handleFunctions = () => {
    switch (btnText) {
      case "Connect via Magic":
        return onOpen();
      case "Edit my MOTI.BIO":
        return navigate(`/edit-bio/${user?.user?.linktree?.linktreeAddress}`);
      case "Claim my MOTI.BIO":
        return navigate("/create-bio");
      default:
        return onOpen();
    }
  };

  const handleClick = () => {
    setIsChecking(true);
    if (!user?.user) {
      setBtnText("Connect via Magic");
      setIsChecking(false);
      setIsChecked(true);
      return;
    }
    if (user?.user && !user?.user?.linktree) {
      setBtnText("Claim my MOTI.BIO");
      setIsChecking(false);
      setIsChecked(true);
      return;
    }
    if (user?.user && user?.user?.linktree) {
      setBtnText("Edit my MOTI.BIO");
      setIsChecking(false);
      setIsChecked(true);
      return;
    }
  };
  useEffect(handleClick, []);

  return (
    <>
      <Box
        display="flex"
        justifyContent={{ base: "center", md: "left" }}
        alignItems="center"
        width="100%"
      >
        <Button
          fontFamily="Sora, sans-serif"
          maxWidth="25rem"
          height={{ base: "2.6rem", md: "4rem" }}
          w={{ base: "11rem", sm: "100%" }}
          leftIcon={
            <Image
              height={{ base: "1rem", md: "2rem" }}
              width={{ base: "1rem", md: "2rem" }}
              src="/images/welcome-page-button-icon.png"
            />
          }
          background="linear-gradient(92.55deg, #AD6EEB -12.49%, #F579B5 52.69%, #FA948F 103.9%)"
          color="#232121"
          borderRadius="90px"
          isLoading={isChecking}
          marginTop="10px"
          fontSize={{ base: "0.8rem", md: "1.5rem" }}
          onClick={isChecked ? handleFunctions : handleClick}
          mb={{ base: "3rem", md: "0" }}
          _hover={{ backgroundColor: "#734C3D", opacity: "0.9" }}
        >
          {btnText}
        </Button>
      </Box>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          maxW="29.5rem"
          width="100%"
          dropShadow="2px 7px 10px 0px #17175340"
          borderRadius="20px"
        >
          <ModalHeader padding="0px">
            <Box
              padding="21px"
              width="100%"
              position="relative"
              justifyContent="center"
              justifyItems="center"
              display="flex"
              fontFamily="sora"
              fontSize="20px"
              lineHeight="24px"
              fontWeight="600"
            >
              <Button
                onClick={onClose}
                position="absolute"
                right="16px"
                top="16px"
                cursor="pointer"
                bg="transparent"
                _hover={{
                  bg: "transparent",
                }}
              >
                <X size="20px" />
              </Button>
            </Box>
          </ModalHeader>
          {!magicData && (
            <ModalBody display="flex" justifyContent="center">
              <img alt="magic-link-icon" src="/images/MagicLinkIcon.png" />
            </ModalBody>
          )}
          <ModalFooter>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyItems="center"
              justifyContent="center"
              pb="10px"
            >
              <Login />
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default GetFinnieModal;
