// For the my Links
import React from "react";
import { Box, Text } from "@chakra-ui/react";

const EmailWalletsAddress = ({ user }) => {
  return (
    // email and the wallet address
    <Box mt={7}>
      <Text
        textAlign="center"
        color="var(--koii-create-topic)"
        fontWeight="700"
      >
        {user?.email}
      </Text>
      <Text
        textAlign="center"
        color="var(--koii-create-topic)"
        fontWeight="400"
      >
        <strong>MOTI.BIO (Sign On) Wallet:</strong> {user?.publicKey}
      </Text>
    </Box>
  );
};

export default EmailWalletsAddress;
