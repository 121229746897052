import { ethers } from "ethers";
import { errorPopup, successPopup, warningPopup } from "../toastCreatePage";
import bs58 from "bs58";

export async function finnieWallet(myWallets, index, setFieldValue, toast) {
  try {
    // Check if MetaMask is installed and enabled
    if (!window.k2 || !window.k2) {
      errorPopup(
        toast,
        `Finnie is not installed or not enabled. Check the Docs https://docs.koii.network/concepts/finnie-wallet/introduction`
      );
      return;
    }

    // wallet verification
    try {
      let signerAddress;
      await window.k2.connect().then((pubKey) => {
        signerAddress = pubKey.toString();
      });

      // get the public key and sign the message
      const nonce = ethers.utils.hexlify(ethers.utils.randomBytes(64)).slice(2);

      const message = `Welcome to MOTI.BIO!\n\nClick to sign in and accept the MOTI.BIO Terms of Service (https://home.moti.bio/terms) and Privacy Policy (https://home.moti.bio/privacy).\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nWallet address:\n${signerAddress}\n\nNonce:\n${nonce}`;
      const signature = await window.k2.signMessage(message);
      const encodeSignature = bs58.encode(signature.signature);

      // set the value and show the success message
      successPopup(toast, `${myWallets[index]["label"]} verified`);
      setFieldValue(`myWallets.${index}.address`, signerAddress);
      setFieldValue(`myWallets.${index}.signature`, encodeSignature);
      setFieldValue(`myWallets.${index}.key`, nonce);

      // await window.k2.disconnect();
    } catch (error) {
      // show the error
      setFieldValue(`myWallets.${index}.address`, "");
      setFieldValue(`myWallets.${index}.signature`, "");
      setFieldValue(`myWallets.${index}.key`, "");
      warningPopup(toast, "You Rejected the request in your wallet");
    }
  } catch (error) {
    // show the error
    console.log("Error:", error);
    errorPopup(toast, "An error occurred. Please try again.");
  }
}
