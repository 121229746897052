import React from "react";

const CrossButton = ({ fillColor }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1842 3.99512L4.09766 21.0816"
        stroke={fillColor}
        strokeWidth="2.44348"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.1896 21.0917L4.08887 3.9873"
        stroke={fillColor}
        strokeWidth="2.44348"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossButton;
