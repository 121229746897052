import imageCompression from "browser-image-compression";
// import bs58 from "bs58";
import { TASK_ADDRESS, RECIPIENT_ADDRESS, TRANSFER_AMOUNT } from "./config";
// import {
//   SystemProgram,
//   Transaction,
//   Connection,
//   clusterApiUrl,
// } from "@_koi/web3.js";

let backend_route;

if (process.env.REACT_APP_DEVELOPMENT) {
  backend_route = process.env["REACT_APP_TASKNET_URL"];
} else {
  backend_route = `${process.env["REACT_APP_TASKNET_URL"]}/task/${process.env["REACT_APP_TASK_ID"]}`;
}

export async function deleteLinktree(nodeList, publicKey) {
  try {
    const requests = nodeList.map((node) =>
      fetch(`${node}/task/${TASK_ADDRESS}/linktree/${publicKey}`, {
        method: "DELETE",
        credentials: "include",
      })
        .then((response) => {
          if (!response.ok) {
            console.error(`Failed request to ${node}: ${response.status}`);
            return null;
          }
          return response.json();
        })
        .catch((error) => {
          console.log(`Error fetching authlist from ${node}:`, error);
          return null;
        })
    );

    const results = await Promise.allSettled(requests);

    for (const result of results) {
      if (result.status === "fulfilled" && result.value) {
        if (result.value === publicKey) {
          return true;
        }
      }
    }

    const response = await fetch(
      `${nodeList[1]}/task/${TASK_ADDRESS}/linktree/${publicKey}`,
      {
        method: "DELETE",
        credentials: "include",
      }
    );

    if (!response.ok) {
      return false;
    }

    const result = await response.json();
    return true;
  } catch (error) {
    console.log(error);
  }
}

export async function allLinktrees(nodeList) {
  try {
    let nodeListIndex = 0;
    let result;

    if (nodeList.length) {
      // while (!result && nodeList[nodeListIndex]) {
      const response = await fetch(`${backend_route}/linktree/list`, {
        method: "GET",
        credentials: "include",
      });

      if (!response.ok) {
        return;
      }
      result = await response.json();
      //   nodeListIndex++;
      // }

      if (result) {
        const linktrees = [...result];
        const total = linktrees.length;
        return total;
      }
      return;
    }
  } catch (error) {
    console.log("Error getting node list:", error);
  }
}

export async function getLinktreeWithUsername(username, nodeList) {
  try {
    let nodeListIndex = 0;
    let result = {
      value: [],
    };

    // while (
    //   (result?.value || result?.value?.length === 0) &&
    //   nodeList[nodeListIndex]
    // ) {
    const response = await fetch(
      `${backend_route}/linktree/get/username/${username}`,
      {
        method: "GET",
        credentials: "include",
      }
    );
    if (!response.ok) {
      return;
    }

    const data = await response.json();

    if (data && data?.length !== 0) {
      result = data;
    }
    //   nodeListIndex++;
    // }

    if (result && result?.length !== 0 && !result?.value) {
      return {
        data: result,
        status: true,
      };
    }
    return {
      data: "",
      status: true,
    };
  } catch (error) {
    console.log("Error getting node list:", error);
  }

  return false;
}

export async function getEndorsements(publicKey, nodeList) {
  try {
    let nodeListIndex = 0;
    let result = {
      value: [],
    };

    // while (
    //   (result?.value || result?.value?.length === 0) &&
    //   nodeList[nodeListIndex]
    // ) {
    const response = await fetch(`${backend_route}/endorsement/${publicKey}`, {
      method: "GET",
      credentials: "include",
    });

    if (!response.ok) {
      return;
    }

    const data = await response.json();

    if (data && data?.length !== 0) {
      result = data;
    }
    //   nodeListIndex++;
    // }

    if (result && result?.length !== 0 && !result?.value) {
      return result;
    }
    return [];
  } catch (error) {
    console.log("Error getting node list:", error);
  }

  return false;
}

export async function getLinktree(publicKey, nodeList) {
  try {
    let nodeListIndex = 0;
    let result = {
      value: [],
    };

    // while (
    //   (!result || result?.value || result?.value?.length === 0) &&
    //   nodeList[nodeListIndex]
    // ) {
    const response = await fetch(`${backend_route}/linktree/get/${publicKey}`, {
      method: "GET",
      credentials: "include",
    });

    if (!response.ok) {
      return;
    }

    const data = await response.json();

    if (data && data?.length !== 0) {
      result = data;
    }
    //   nodeListIndex++;
    // }

    https: if (result && result?.length !== 0 && !result.value) {
      return {
        data: result,
        status: true,
      };
    }
    return {
      data: "",
      status: true,
    };
  } catch (error) {
    console.log("Error getting node list:", error);
  }

  return false;
}

// upload and get the profile image
export async function setProfileImage(
  publicKey,
  currentOrUpdateImage,
  previousImagePath,
  cookies
) {
  try {
    if (currentOrUpdateImage.size / (1024 * 1024) >= 0.06) {
      const options = {
        maxSizeMB: 0.045,
        maxWidthOrHeight: 800,
        useWebWorker: true,
        quality: 1,
      };

      // compress the file
      const compressedFile = await imageCompression(
        currentOrUpdateImage,
        options
      );

      console.log(compressedFile.size / (1024 * 1024), " MORE THAN 1");

      return await uploadImage(
        publicKey,
        compressedFile,
        previousImagePath,
        cookies
      );
    } else {
      return await uploadImage(
        publicKey,
        currentOrUpdateImage,
        previousImagePath,
        cookies
      );
    }
  } catch (error) {
    console.error("Error set profile image:", error);
    throw error;
  }
}
async function uploadImage(
  publicKey,
  currentOrUpdateImage,
  previousImagePath,
  cookies
) {
  try {
    const reader = new FileReader();

    const readPromise = new Promise((resolve, reject) => {
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
    });

    reader.readAsDataURL(currentOrUpdateImage);

    const imageData = await readPromise;

    const response = await fetch(`${backend_route}/img/${publicKey}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": "en-US,en;q=0.9",
        Cookie: cookies,
      },
      body: JSON.stringify({
        imageData,
        previousImagePath,
      }),
    });
    const result = await response.json();

    return result;
  } catch (error) {
    console.error("Error set profile image:", error);
    throw error;
  }
}
export async function getProfileImage(publicKey, imagePath) {
  try {
    if (imagePath !== undefined || imagePath !== "" || imagePath !== null) {
      const response = await fetch(
        `${backend_route}/img/${publicKey}?imagePath="${imagePath}"`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!response.ok) {
        console.error("Error get profile image:");
        throw new Error(`Error get profile image: ${response.status}`);
      }

      const result = await response.json();

      const metadata = result.split(",");
      const formatMatch = metadata[0].match(/^data:image\/([a-zA-Z.]+);base64/);
      const imageFormat = formatMatch ? formatMatch[1] : "unknown";
      const getImageFormatProper = imageFormat.replace(".", "");

      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = result;
        img.onload = () => {
          const desiredWidth = img.width;
          const desiredHeight = img.height;
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = desiredWidth;
          canvas.height = desiredHeight;
          ctx.drawImage(img, 0, 0, desiredWidth, desiredHeight);

          const highQualityBase64 = canvas.toDataURL(
            `image/${getImageFormatProper}`,
            1.0
          );

          resolve(highQualityBase64);
        };
        img.onerror = (error) => {
          reject(error);
        };
      });
    }
  } catch (error) {
    console.error("Error get profile image:", error);
    throw error;
  }
}

// INFO :::: NOT IN USE ANYWHERE BUT WE CAN UPDATE FROM AXIOS TO FETCH AND USE IT IN THE CODE ::::

// update the linktree
// export async function updateLinktree(data, publicKey, nodeList, username) {
//   const messageString = JSON.stringify(data);
//   try {
//     const signatureRaw = await window.k2.signMessage(messageString);
//     const payload = {
//       data,
//       publicKey: publicKey,
//       signature: bs58.encode(signatureRaw.signature),
//       username,
//     };
//     let nodeListIndex = 1;
//     let result;

//     while (!result && nodeList[nodeListIndex]) {
//       result = await axios
//         .put(`${backend_route}/linktree`, {
//           payload,
//         })
//         .then((res) => res.data)
//         .catch((error) => console.log(`Error updating linktree:`, error));
//       nodeListIndex++;
//     }

//     if (result?.message) {
//       return result;
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }
// export async function setLinktreeMagic(data, publicKey, nodeList, username) {
//   const messageString = JSON.stringify(data);
//   try {
//     const signatureRaw = await window.k2.signMessage(messageString);
//     const payload = {
//       data,
//       publicKey: publicKey,
//       signature: bs58.encode(signatureRaw.signature),
//       username,
//     };
//     let nodeListIndex = 0;
//     let result;

//     while (!result && nodeList[nodeListIndex]) {
//       result = await axios
//         .post(`${backend_route}/linktree`, {
//           payload,
//         })
//         .then((res) => res.data)
//         .catch((error) => console.log(`Error setting linktree:`, error));
//       nodeListIndex++;
//     }

//     if (result?.message) {
//       return result;
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }
// export async function getAuthList(publicKey, nodeList) {
//   try {
//     const requests = nodeList.map((node) =>
//       axios
//         .get(`${node}/task/${TASK_ADDRESS}/authlist/get/${publicKey}`)
//         .then((res) => res.data)
//         .catch((error) =>
//           console.log(`Error fetching authlist from ${node}:`, error)
//         )
//     );

//     const results = await Promise.allSettled(requests);

//     for (const result of results) {
//       if (result.status === "fulfilled" && result.value === publicKey) {
//         return true;
//       }
//     }
//     return false;
//   } catch (error) {
//     console.log("Error getting node list:", error);
//   }
// }
// export async function transferKoii(nodeList) {
//   const connection = new Connection(clusterApiUrl("devnet"));
//   const blockHash = await connection.getRecentBlockhash();
//   const feePayer = window.k2.publicKey;

//   const transaction = new Transaction();
//   transaction.recentBlockhash = blockHash.blockhash;
//   transaction.feePayer = feePayer;

//   transaction.add(
//     SystemProgram.transfer({
//       fromPubkey: window.k2.publicKey,
//       toPubkey: new window.solanaWeb3.PublicKey(RECIPIENT_ADDRESS),
//       lamports: Number(
//         TRANSFER_AMOUNT +
//           (await connection.getMinimumBalanceForRentExemption(100)) +
//           1000
//       ),
//     })
//   );

//   const payload = transaction.serializeMessage();
//   const signature = await window.k2.signAndSendTransaction(payload);

//   if (signature) {
//     const authdata = {
//       pubkey: window.k2.publicKey.toString(),
//     };
//     let nodeListIndex = 1;
//     let result;
//     while (!result) {
//       result = await axios
//         .post(`${backend_route}/authlist`, {
//           authdata,
//         })
//         .then((res) => res.data === window.k2.publicKey.toString())
//         .catch((error) =>
//           console.log(
//             `Error fetching authlist from ${nodeList[nodeListIndex]}:`,
//             error
//           )
//         );
//       nodeListIndex++;
//       if (result) return result;
//     }
//   }
// }
// export async function setLinktree(data, publicKey, nodeList, username) {
//   const messageString = JSON.stringify(data);
//   try {
//     const koiiTransfer = await transferKoii(nodeList);
//     const signatureRaw = await window.k2.signMessage(messageString);
//     const payload = {
//       data,
//       publicKey: publicKey,
//       signature: bs58.encode(signatureRaw.signature),
//       username,
//     };

//     // let nodeListIndex = 0;
//     let result;

//     // while (!result && nodeList[nodeListIndex]) {
//     const response = await fetch(`${backend_route}/linktree`, {
//       method: "POST",
//       credentials: "include",
//       body: JSON.stringify({ payload }),
//
//       //     });

//     //   nodeListIndex++;
//     // }

//     if (response.ok) {
//       const getMessage = await response.json();
//       return getMessage;
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }
