// For the my Links
import React from "react";
import { Box, Flex, Input, Text, Spacer } from "@chakra-ui/react";
import { ErrorMessage, Field, FieldArray } from "formik";
import { PlusCircle } from "lucide-react";
import { DeleteIcon } from "@chakra-ui/icons";

const MyLinks = ({ setFieldValue, errors, values, linksGroup }) => {
  return (
    <>
      {/* Custom Links */}
      <Text
        fontSize={{ base: "xl", md: "xl" }}
        fontWeight="700"
        my={5}
        fontFamily="Poppins"
        color="var(--koii-create-topic)"
      >
        Add Custom Links
      </Text>
      <Text
        fontSize="1rem"
        fontWeight="700"
        fontFamily="Poppins"
        color="var(--koii-create-topic)"
      >
        Primary Link
      </Text>
      <Text
        fontSize="0.8rem"
        fontWeight="500"
        fontFamily="Poppins"
        color="#6B6B72"
      >
        Your primary link will stand out with a different color
      </Text>
      {/* show the links */}
      <FieldArray name="links">
        {({ push, remove }) => (
          <div>
            {values.links.map((_, index) => (
              <Box key={index}>
                <Box mt={6} width="100%">
                  <Flex
                    width="100%"
                    flexDirection={{ base: "column", md: "row" }}
                    key={index + 1}
                    mt={2}
                    gap="20px"
                    alignItems={{ base: "end", md: "center" }}
                  >
                    <Box w={{ base: "100%", md: "40%" }}>
                      <Box w="100%">
                        <Text color="var(--koii-create-topic)" width="100%">
                          {index === 0 ? "Primary" : ""} Link Label
                        </Text>
                        <Field
                          background="var(--koii-input-background-color)"
                          placeholder="Eg: LinkedIn"
                          style={{
                            borderRadius: "12px",
                            color: "var(--koii-create-topic)",
                          }}
                          isInvalid={
                            errors.links && !!errors.links[index]?.label
                          }
                          name={`links.${index}.label`}
                          label="Link Name"
                          as={Input}
                          border="1px solid var(--koii-input-border-color)"
                        />
                      </Box>
                      <Box
                        display={{ base: "block", md: "none" }}
                        mt="0px"
                        w={{ base: "100%" }}
                      >
                        <Text className="error">
                          <ErrorMessage name={`links.${index}.label`} />
                        </Text>
                      </Box>
                    </Box>
                    <Spacer />
                    <Box
                      w={{ base: "100%", md: "58%" }}
                      mt={{ base: "0px", md: "0px" }}
                    >
                      <Box w={index === 0 ? "100%" : "95%"}>
                        <Text
                          color="var(--koii-create-topic)"
                          width={{ base: "150px", md: "auto" }}
                        >
                          {index === 0 ? "Primary" : ""} Link URL
                        </Text>
                        {/* link and delete button */}
                        <Box display="flex" alignItems="center" gap="10px">
                          <Field
                            background="var(--koii-input-background-color)"
                            placeholder="Eg: https://www.linkedin.com/in/name"
                            errorBorderColor="red.500"
                            style={{
                              borderRadius: "12px",
                              color: "var(--koii-create-topic)",
                            }}
                            isInvalid={
                              errors.links && !!errors.links[index]?.redirectUrl
                            }
                            name={`links.${index}.redirectUrl`}
                            label="Link URL"
                            as={Input}
                            border="1px solid var(--koii-input-border-color)"
                            width="100%"
                          />
                          {/* for the delete button */}
                          {index > 0 && (
                            <>
                              <Box display={{ base: "flex", md: "none" }}>
                                <DeleteIcon
                                  onClick={() => remove(index)}
                                  style={{
                                    color: "#A6A6A6",
                                    fontSize: "1.3rem",
                                    cursor: "pointer",
                                  }}
                                />
                              </Box>
                              <Box display={{ base: "none", md: "flex" }}>
                                <DeleteIcon
                                  onClick={() => remove(index)}
                                  style={{
                                    color: "#A6A6A6",
                                    fontSize: "1.5rem",
                                    cursor: "pointer",
                                  }}
                                />
                              </Box>
                            </>
                          )}
                        </Box>

                        {/* show the error message */}
                        <Box
                          display={{ base: "block", md: "none" }}
                          mt="0px"
                          w={{ base: "100%" }}
                        >
                          <Text className="error">
                            <ErrorMessage name={`links.${index}.redirectUrl`} />
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>

                  {/* error message */}
                  <Flex
                    width="100%"
                    flexDirection={{ base: "column", md: "row" }}
                    key={index}
                    display={{ base: "none", md: "flex" }}
                    alignItems={{ base: "end", md: "center" }}
                  >
                    <Box mt="0px" w={{ base: "100%", md: "45%" }}>
                      <Text className="error">
                        <ErrorMessage name={`links.${index}.label`} />
                      </Text>
                    </Box>
                    <Spacer />
                    <Box mt="0px" w={{ base: "100%", md: "58%" }}>
                      <Text className="error">
                        <ErrorMessage name={`links.${index}.redirectUrl`} />
                      </Text>
                    </Box>
                  </Flex>

                  {/* space */}
                  <Spacer />
                </Box>

                {/* heading for the secondary links */}
                {values.links.length > 1 && index === 0 && (
                  <>
                    <Text
                      marginTop="10px"
                      fontSize="1rem"
                      fontWeight="700"
                      fontFamily="Poppins"
                      color="var(--koii-create-topic)"
                    >
                      Secondary Link
                    </Text>
                    <Text
                      fontSize="0.8rem"
                      fontWeight="500"
                      fontFamily="Poppins"
                      color="#6B6B72"
                    >
                      Additional links to include in your profile
                    </Text>
                  </>
                )}
              </Box>
            ))}

            {/* add button the secondary link */}
            <Box
              display="flex"
              alignItems="center"
              gap="8px"
              mt={{ base: "0", md: 5 }}
              onClick={() => push(linksGroup)}
              cursor="pointer"
            >
              <PlusCircle
                fill="var(--koii-icon-fill-color)"
                color="var(--koii-icon-color)"
                style={{
                  borderColor: "var(--koii-icon-fill-color) !important",
                }}
                size="32px"
              />
              <Text
                fontSize="0.9rem"
                fontWeight="500"
                fontFamily="Poppins"
                color="var(--koii-create-topic)"
              >
                Add secondary link
              </Text>
            </Box>
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default MyLinks;
