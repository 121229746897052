import "./App.css";
import HomePage from "./pages/Home";


const App = () => {
  return (
    <>
      <HomePage />
    </>
  );
};

export default App;
