import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Input,
  InputGroup,
  InputRightElement,
  Image,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import uuid from "react-uuid";
import { getLinktreeWithUsername, setProfileImage } from "../api";
import { useNavigate, useLocation } from "react-router-dom";
import { useWalletContext } from "../contexts";
import { Oval } from "react-loader-spinner";
import { logoutUser } from "../redux/slice/userSlice";
import MasterMagic from "../components/modals/magic/MasterMagic";
import { X, Copy } from "lucide-react";
import { LogOut } from "lucide-react";
import {
  themeApplier,
  createThemeApplier,
  getRadioButtonScheme,
} from "../helpers";
import { magic } from "../components/modals/magic/MasterMagic";
import LinktreeEditForm from "../components/form/edit-form";
import { handleCopy } from "../helpers/copyText";
import { useLogoutModalContext } from "../contexts/logoutModalContext";
import LogoutModal from "../components/LogoutModal";
import { useSelector } from "react-redux";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./editLinktree.css";
import Endorsements from "../components/form/endorsement";
import footerImage from "../img/koii_dark.png";
import footerImage2 from "../img/koii_light.png";

function trackPageView(location) {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
}

document.documentElement.setAttribute("data-theme", "dark");

const EditLinktree = () => {
  const dispatch = useDispatch();
  const { nodeList, userData, setMagicPayload, magicPayload } =
    useWalletContext();

  // for the tabs
  const [activeTab, setActiveTab] = useState("editProfile");

  // setting new image
  const [image, setImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [isFetchingUserData, setIsFetchingUserData] = useState(false);
  const [user, setUser] = useState(null);
  const [choosenTheme, setChoosenTheme] = useState("Dark");
  const [radioColorScheme, setRadioColorScheme] = useState("yellow");
  // boolean value if image change
  const [imageChanged, setImageChanged] = useState(false);
  const [getPublicKey, setPublicKey] = useState("");
  const currentUser = useSelector((state) => state.user?.user);
  const {
    isOpen: isLogoutModalOpen,
    onOpen: openLogoutModal,
    onClose: closeLogoutModal,
  } = useLogoutModalContext();

  const [choosenLabelTheme, setChoosenLabelTheme] = useState(
    user?.linktree?.choosenLabelTheme || "label-one"
  );

  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    document.title = "Edit Your Profile | MOTI.BIO";
  });
  const { onOpen, onClose, isOpen } = useDisclosure();
  useEffect(() => {
    trackPageView(location);
  }, [location]);
  const query = location.pathname.split("/")[2];

  const handleLabelSelection = (e) => {
    setChoosenLabelTheme(e);
  };

  useEffect(() => {
    document.title = "Edit Your Profile | MOTI.BIO";
    if (nodeList.length === 0) return;
    if (!currentUser?.publicKey) {
      toast({
        title: "You are not logged in!",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      navigate("/login");
      return;
    }

    const fetchUserData = async () => {
      try {
        setIsFetchingUserData(true);
        const userResponse = await getLinktreeWithUsername(query, nodeList);
        setUser(userResponse.data.data);
        setPublicKey(userResponse.data.publicKey);
        if (!userResponse.data.data) {
          toast({
            title: "User Not Found!",
            description: "User not found, you'll be redirected to login page.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          magic.user.logout();
          dispatch(logoutUser());
          navigate("/login");
        }

        if (userResponse?.data?.publicKey !== currentUser?.publicKey) {
          toast({
            title: "Not Authorized!",
            description:
              "You are not authorized to edit this profile, you'll be redirected to login page",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          magic.user.logout();
          dispatch(logoutUser());
          navigate("/login", { replace: true });
        }

        setIsFetchingUserData(false);
      } catch (error) {
        console.log("Error", error);
        setIsFetchingUserData(false);
      }
    };

    fetchUserData();
  }, [nodeList.length, query, currentUser?.publicKey]);

  useEffect(() => {
    magic.user.isLoggedIn().then((e) => {
      if (!e) {
        dispatch(logoutUser());
        navigate("/login");
      }
    });
  });

  useEffect(() => {
    themeApplier(user?.linktree?.theme);
    setChoosenTheme(user?.linktree?.theme);
    handleThemeSelection(user?.linktree?.theme);
  }, [user?.linktree?.theme]);

  function handleThemeSelection(theme) {
    setChoosenTheme(theme);
    createThemeApplier(theme);
    const colorScheme = getRadioButtonScheme(theme);
    setRadioColorScheme(colorScheme);
  }

  // get the links
  const insertKey = (links) => {
    return links.map((item, index) => {
      return {
        ...item,
        isFavorite: index === 0 ? true : false,
        key: uuid(),
      };
    });
  };

  // get the mySocials
  const insertMySocialsKey = (mySocials) => {
    return mySocials.map((item) => {
      const { placeholder, ...rest } = item;
      return {
        ...rest,
        key: uuid(),
      };
    });
  };

  const insertMyWalletsKey = (myWallets) => {
    return myWallets.map((item) => ({
      ...item,
      key: item.key && item.key.length > 0 ? item.key : uuid(),
    }));
  };

  useEffect(() => {
    setMagicPayload(null);
  }, []);

  // get the values from the both forms
  const handleSubmitMagic = async (values, actions) => {
    try {
      const documentCookies = `; ${document.cookie}`;
      const getCookieValue = (name) => {
        const parts = documentCookies.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(";").shift();
        return null;
      };
      const awsAlbCookie = getCookieValue("AWSALB");
      const awsAlbCorsCookie = getCookieValue("AWSALBCORS");
      const awsMotiSessionCookie = getCookieValue("motisession");
      const cookies = [
        awsAlbCookie ? `AWSALB=${awsAlbCookie}` : "",
        awsAlbCorsCookie ? `AWSALBCORS=${awsAlbCorsCookie}` : "",
        awsMotiSessionCookie ? `motisession=${awsMotiSessionCookie}` : "",
      ]
        .filter(Boolean)
        .join("; ");

      // add links
      values.links = insertKey(values.links, values.linktreeAddress);

      // add the mySocials
      values.mySocials = insertMySocialsKey(values.mySocials);

      // add the myWallets
      values.myWallets = insertMyWalletsKey(values.myWallets);

      setIsLoading(true);

      // get the new image path
      let getImagePath;
      if (image && imageChanged) {
        if (image?.size > 1024 * 1024 * 4) {
          setIsLoading(false);
          return toast({
            title: "Try again",
            description:
              "Your image is too powerful! Please reupload a file below 4MB.",
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
        }

        getImagePath = await setProfileImage(
          getPublicKey,
          image,
          user?.linktree?.koiiImagePath,
          cookies
        );

        setIsLoading(true);
        uploadToDb(values, image, getImagePath);
      } else {
        uploadToDb(values, image, null);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  function uploadToDb(values, image, getImagePath) {
    const { endorsement, ...filteredValues } = values;

    const payload = {
      uuid: user?.uuid,
      linktree: {
        ...filteredValues,
        image: image
          ? getImagePath.protocolLink
            ? getImagePath.protocolLink
            : user?.linktree?.image
          : user?.linktree?.image,
        koiiImagePath: image
          ? getImagePath.imagepath
            ? getImagePath.imagepath
            : user?.linktree?.koiiImagePath
          : user?.linktree?.koiiImagePath,
        background: "",
        theme: choosenTheme,
        choosenLabelTheme: choosenLabelTheme,
        ...(values.endorsement && values.endorsement.length > 0
          ? { endorsement: values.endorsement }
          : {}),
      },
      timestamp: Date.now(),
    };
    setMagicPayload(payload);
  }

  if (isFetchingUserData)
    return (
      <Box
        height="90vh"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Oval
          height={80}
          width={80}
          color="#ffffff"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#ffffff"
          strokeWidth={2}
          strokeWidthSecondary={2}
          colorScheme={getRadioButtonScheme(userData?.theme)}
        />
      </Box>
    );

  const Logo = ["Gradient", "Dark"].includes(choosenTheme)
    ? "/images/DarkThemeLogo.png"
    : "/images/logo.png";
  const color = ["Gradient", "Dark"].includes(choosenTheme) ? "white" : "black";

  const linksGroup = { label: "", redirectUrl: "", key: "", isFavorite: false };
  const mySocials = [
    {
      label: "X (Twitter)",
      content: "",
      key: "",
    },
    {
      label: "Instagram",
      content: "",
      key: "",
    },
    {
      label: "Facebook",
      content: "",
      key: "",
    },
    {
      label: "Linkedln",
      content: "",
      key: "",
    },
    {
      label: "Telegram",
      content: "",
      key: "",
    },
    {
      label: "Whatsapp",
      content: "",
      key: "",
    },
    {
      label: "Email",
      content: "",
      key: "",
    },
    {
      label: "Discord",
      content: "",
      key: "",
    },
  ];
  const myWallets = [
    {
      label: "Bitcoin (BTC)",
      address: "",
      signature: "",
      key: "",
    },
    {
      label: "Ethereum (ETH)",
      address: "",
      signature: "",
      key: "",
    },
    {
      label: "Solana (SOL)",
      address: "",
      signature: "",
      key: "",
    },
    {
      label: "Koii/K2 (KOII)",
      address: "",
      signature: "",
      key: "",
    },
    {
      label: "Polygon (MATIC)",
      address: "",
      signature: "",
      key: "",
    },
    {
      label: "TRON (TRX)",
      address: "",
      signature: "",
      key: "",
    },
    {
      label: "Arbitrum (ARB)",
      address: "",
      signature: "",
      key: "",
    },
  ];

  return (
    <>
      {/* for the logout button and logo on top*/}
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="2rem 2rem 0 2rem"
      >
        <Link to="https://moti.bio">
          <img src={Logo} alt="main logo" style={{ height: "40px" }} />
        </Link>
        <Button
          _hover={{ color, opacity: "0.9" }}
          color={color}
          onClick={openLogoutModal}
          leftIcon={<LogOut color={color} size="20px" />}
          backgroundColor="transparent"
          width="7.8rem"
          height="2.5rem"
          borderRadius="90px"
          border={`1px solid ${color}`}
        >
          Logout
        </Button>
      </Box>

      {/* if user info then show */}
      {user?.linktree ? (
        <>
          <Flex justify="center" align="center" width="100%">
            <Box
              py={{ base: "3rem", md: "5rem" }}
              px={8}
              margin="auto"
              maxWidth={{ base: "100%", md: "800px" }}
              className="createLinktree"
            >
              {/* for the tabs */}
              <Box className="tab-container" mb={5}>
                <Flex justify="center" align="center">
                  <Box
                    px={5}
                    py={2}
                    cursor="pointer"
                    className={activeTab === "editProfile"}
                    onClick={() => setActiveTab("editProfile")}
                  >
                    <Text
                      fontSize={{ base: "2xl", md: "2rem" }}
                      fontWeight={"700"}
                      my={6}
                      color="var(--koii-create-topic)"
                      fontFamily="Poppins"
                      textAlign="center"
                    >
                      Edit Your Profile
                    </Text>
                  </Box>
                  <Box
                    px={5}
                    py={2}
                    cursor="pointer"
                    className={activeTab === "manageEndorsements"}
                    onClick={() => setActiveTab("manageEndorsements")}
                  >
                    <Text
                      fontSize={{ base: "2xl", md: "2rem" }}
                      fontWeight={"700"}
                      my={6}
                      color="var(--koii-create-topic)"
                      fontFamily="Poppins"
                      textAlign="center"
                    >
                      Manage Endorsements
                    </Text>
                  </Box>
                </Flex>
                <Divider color="var(--koii-create-topic)" borderWidth="1px" />
              </Box>

              {/* for the active and show the content */}
              {activeTab === "editProfile" && (
                <Box>
                  <Text
                    fontSize={{ base: "xl", md: "1.2rem" }}
                    fontWeight={"700"}
                    fontFamily="Poppins"
                    my={4}
                    color="var(--koii-create-topic)"
                  >
                    Profile Link
                  </Text>
                  <LinktreeEditForm
                    choosenLabelTheme={choosenLabelTheme}
                    choosenTheme={choosenTheme}
                    handleSubmit={handleSubmitMagic}
                    userData={user?.linktree}
                    isUploadingImage={isUploadingImage}
                    isLoading={isLoading}
                    handleLabelSelection={handleLabelSelection}
                    handleThemeSelection={handleThemeSelection}
                    setImage={setImage}
                    magicPayload={magicPayload}
                    image={image}
                    colorScheme={radioColorScheme}
                    setImageChanged={setImageChanged}
                    getPublicKey={getPublicKey}
                    linksGroup={linksGroup}
                    // mySocials group links
                    mySocials={mySocials}
                    // myWallets group links
                    myWallets={myWallets}
                  />
                </Box>
              )}

              {/* view the Endorsements */}
              {activeTab === "manageEndorsements" && (
                <Box>
                  <Box
                    w={{ base: "80%", md: "100%" }}
                    mx={{ base: "auto", md: "0px" }}
                    my={4}
                  >
                    <Text
                      fontSize={{ base: "xl", md: "25px" }}
                      textAlign={{ base: "center", md: "left" }}
                      fontWeight={"700"}
                      fontFamily="Poppins"
                      lineHeight="32.5px"
                      letterSpacing={"-2%"}
                      align={"center"}
                      color="var(--koii-create-topic)"
                    >
                      Endorsements Shown
                    </Text>
                    <Divider
                      color="var(--koii-create-topic)"
                      borderWidth="0.5px"
                      w={{ base: "70%", md: "60%" }}
                      mx={{ base: "auto", md: "0px" }}
                      my={2}
                    />
                    <Text
                      fontSize="1rem"
                      fontWeight="700"
                      fontFamily="Poppins"
                      color="var(--koii-create-topic)"
                    >
                      Endorsements in this section are shown on your MOTI.BIO
                      Profile.
                    </Text>
                  </Box>
                  <Endorsements
                    choosenTheme={choosenTheme}
                    handleSubmit={handleSubmitMagic}
                    userData={user?.linktree}
                    isLoading={isLoading}
                    getPublicKey={getPublicKey}
                    nodeList={nodeList}
                    magicPayload={magicPayload}
                  />
                </Box>
              )}
              <MasterMagic />
            </Box>
          </Flex>

          {color === "black" ? (
            <Box as="footer">
              <Image
                src={footerImage}
                alt="Footer Image"
                objectFit="contain"
                boxSize={{ base: "150px", md: "200px" }}
              />
            </Box>
          ) : (
            <Box as="footer">
              <Image
                src={footerImage2}
                alt="Footer Image"
                objectFit="contain"
                boxSize={{ base: "150px", md: "200px" }}
              />
            </Box>
          )}
        </>
      ) : (
        <Box marginTop={"300px"}>
          <Oval
            height={80}
            width={80}
            color="#ffffff"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#ffffff"
            strokeWidth={2}
            strokeWidthSecondary={2}
            colorScheme={getRadioButtonScheme(userData?.theme)}
          />
        </Box>
      )}

      {/* open model update */}
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          maxW="29.5rem"
          width="100%"
          dropShadow="2px 7px 10px 0px #17175340"
          borderRadius="20px"
        >
          <ModalHeader padding="0px">
            <Box
              padding="21px"
              width="100%"
              position="relative"
              justifyContent="space-between"
              justifyItems="center"
              display="flex"
              fontSize="20px"
              lineHeight="24px"
              fontWeight="600"
            >
              <Button
                onClick={onClose}
                position="absolute"
                right="16px"
                top="16px"
                cursor="pointer"
                bg="transparent"
                _hover={{
                  bg: "transparent",
                }}
              >
                <X size="20px" />
              </Button>
            </Box>
          </ModalHeader>
          <ModalBody>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Image src="/images/profile_claimed.png" />
            </Box>
            <Text
              fontWeight="600"
              fontSize="1.5rem"
              fontFamily="Poppins"
              color="black"
              textAlign="center"
            >
              Profile Claimed
            </Text>
            <Text
              fontWeight="400"
              my="10px"
              fontSize="0.9remrem"
              fontFamily="Poppins"
              color="black"
              textAlign="center"
            >
              Congratulations, you have successfully claimed your unique
              MOTI.BIO link ✌️
            </Text>
          </ModalBody>
          <ModalFooter>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyItems="center"
              justifyContent="center"
              pb="10px"
            >
              {/* <Login /> */}
              <InputGroup>
                <Input
                  color="black"
                  disabled
                  _disabled={{
                    backgroundColor: "#D5D5D5",
                    opacity: "1",
                    border: "1px solid #D5D5D5",
                  }}
                  defaultValue={user?.linktree?.linktreeAddress}
                />
                <InputRightElement
                  onClick={() => handleCopy(user?.linktree?.linktreeAddress)}
                  cursor="pointer"
                  ml="10px"
                  mt={1}
                >
                  <Copy color="#494747" />
                </InputRightElement>
              </InputGroup>
            </Box>
            <Button
              onClick={onClose}
              fontFamily="Poppins, sans-serif"
              w="100%"
              backgroundColor={"#100E1E"}
              color={"white"}
              height="3.3rem"
              borderRadius="90px"
              _hover={{ backgroundColor: "#100E1E", opacity: "0.9" }}
              mt={5}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* logout model */}
      <LogoutModal isOpen={isLogoutModalOpen} onClose={closeLogoutModal} />
    </>
  );
};

export default EditLinktree;
