// For the profile link, name and BIO
import React from "react";
import { Box, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import { Field } from "formik";
import StyledInput from "../../InputField";
import { PreviewImage } from "../preview-image";

// upload theme
const uploadIcon = {
  Dark: "/images/Theme2-upload-icon.png",
  Mint: "/images/Theme1-upload-icon.png",
  Gradient: "/images/Theme3-upload-icon.png",
  "Gradient-Two": "/images/Theme4-upload-icon.png",
};

const ProfileImage = ({
  errors,
  image,
  choosenTheme,
  setFiles,
  setImage,
  setImageName,
  page,
  getBase64ImagePath,
  setImageChanged,
  userData,
}) => {
  return (
    <>
      {page && (
        <Text
          fontSize={{ base: "xl", md: "xl" }}
          fontWeight="700"
          my={5}
          fontFamily="Poppins"
          color="var(--koii-create-topic)"
        >
          Basic Info
        </Text>
      )}
      <Box
        mt={15}
        display="flex"
        width="100%"
        gap={{ base: "20px", md: "40px" }}
        flexDirection={{ base: "column", md: "row" }}
      >
        {/* profile picture */}
        {!page ? (
          // edit page
          <Box
            maxWidth={{ base: "auto", md: "20%" }}
            display={"flex"}
            justifyContent={{ base: "center", md: "flex-start" }}
            width="100%"
          >
            {getBase64ImagePath || image || userData?.image ? (
              <div style={{ position: "relative" }}>
                <img
                  defaultValue={
                    image
                      ? image
                      : getBase64ImagePath
                      ? getBase64ImagePath
                      : userData?.image
                      ? userData?.image
                      : "/images/DefaultUserProfile.png"
                  }
                  style={{
                    minWidth: "150px",
                    minHeight: "150px",
                    maxWidth: "150px",
                    maxHeight: "150px",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  src={
                    image
                      ? URL.createObjectURL(image)
                      : getBase64ImagePath
                      ? getBase64ImagePath
                      : userData?.image
                      ? userData?.image
                      : "/images/DefaultUserProfile.png"
                  }
                />
                <Field name="image">
                  {({ form, field }) => {
                    const { setFieldValue } = form;
                    return (
                      <>
                        <input
                          type="file"
                          hidden
                          name="image"
                          onChange={(e) => {
                            setImageChanged(true);
                            setImage(e.target.files[0]);
                            setFieldValue("image", e.target.files[0].name);
                          }}
                          style={{
                            position: "absolute",
                            top: "100px",
                            left: "100px",
                            zIndex: "2",
                          }}
                          id="fileInput"
                        />
                        <label htmlFor="fileInput">
                          <img
                            style={{
                              zIndex: "1",
                              position: "absolute",
                              top: "100px",
                              left: "100px",
                              cursor: "pointer",
                            }}
                            src={uploadIcon[userData?.theme || choosenTheme]}
                          />
                        </label>
                      </>
                    );
                  }}
                </Field>
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <img
                  style={{ zIndex: "0" }}
                  src="/images/DefaultUserProfile.png"
                />
                <Field name="image">
                  {({ form, field }) => {
                    const { setFieldValue } = form;
                    return (
                      <>
                        <input
                          type="file"
                          hidden
                          onChange={async (e) => {
                            setImageChanged(true);
                            setImage(e.target.files[0]);
                            setFieldValue("image", e.target.files[0].name);
                          }}
                          style={{
                            position: "absolute",
                            top: "105px",
                            left: "100px",
                            zIndex: "2",
                          }}
                          id="fileInput"
                        />
                        <label htmlFor="fileInput">
                          <img
                            style={{
                              zIndex: "1",
                              position: "absolute",
                              top: "105px",
                              left: "100px",
                              cursor: "pointer",
                            }}
                            src={uploadIcon[choosenTheme]}
                          />
                        </label>
                      </>
                    );
                  }}
                </Field>
              </div>
            )}
          </Box>
        ) : (
          // create page
          <Box
            maxWidth={{ base: "auto", md: "20%" }}
            display="flex"
            justifyContent={{ base: "center", md: "start" }}
            width="100%"
          >
            {image ? (
              <div style={{ position: "relative" }}>
                <PreviewImage width={100} height={100} file={image} />
                <Field name="image">
                  {({ form, field }) => {
                    const { setFieldValue } = form;
                    return (
                      <>
                        <input
                          type="file"
                          hidden
                          onChange={async (e) => {
                            setFiles(e.target.files);
                            setImage(e.target.files[0]);
                            setImageName(e.target.files[0].name);
                            setFieldValue("image", e.target.files[0].name);
                          }}
                          style={{
                            position: "absolute",
                            top: "100px",
                            left: "100px",
                            zIndex: "2",
                          }}
                          id="fileInput"
                        />
                        <label htmlFor="fileInput">
                          <img
                            alt="user upload icon"
                            style={{
                              zIndex: "1",
                              position: "absolute",
                              top: "100px",
                              left: "100px",
                              cursor: "pointer",
                            }}
                            src={uploadIcon[choosenTheme]}
                          />
                        </label>
                      </>
                    );
                  }}
                </Field>
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <img
                  alt="default user"
                  style={{ zIndex: "0" }}
                  src="/images/DefaultUserProfile.png"
                />
                <Field name="image">
                  {({ form, field }) => {
                    const { setFieldValue } = form;
                    return (
                      <>
                        <input
                          type="file"
                          hidden
                          onChange={async (e) => {
                            setFiles(e.target.files);
                            setImage(e.target.files[0]);
                            setImageName(e.target.files[0].name);
                            setFieldValue("image", e.target.files[0].name);
                          }}
                          style={{
                            position: "absolute",
                            top: "100px",
                            left: "100px",
                            zIndex: "2",
                          }}
                          id="fileInput"
                        />
                        <label htmlFor="fileInput">
                          <img
                            alt="user upload icon"
                            style={{
                              zIndex: "1",
                              position: "absolute",
                              top: "100px",
                              left: "100px",
                              cursor: "pointer",
                            }}
                            src={uploadIcon[choosenTheme]}
                          />
                        </label>
                      </>
                    );
                  }}
                </Field>
              </div>
            )}
          </Box>
        )}

        {/* name and description */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          maxW={{ md: "80%" }}
          width="100%"
        >
          <Flex
            flexDirection={{ base: "column-reverse", md: "column" }}
            width="100%"
          >
            <Box mb={{ base: 3 }}>
              <div
                style={{
                  width: "100%",
                }}
              >
                <Text
                  fontSize="0.9rem"
                  fontWeight="500"
                  fontFamily="Poppins"
                  color="var(--koii-create-topic)"
                >
                  Name <span style={{ color: "#FD6E6B" }}>*</span>
                </Text>
                <Box mt={1.5} mb={5} width={{ base: "100%" }}>
                  <StyledInput
                    name="name"
                    label="Full Name"
                    as={Input}
                    placeholder="Your name here"
                    isInvalid={errors.name}
                  />
                </Box>
                <Box>
                  <Text
                    fontSize="0.9rem"
                    fontWeight="500"
                    fontFamily="Poppins"
                    color="var(--koii-create-topic)"
                  >
                    Bio <span style={{ color: "#FD6E6B" }}>*</span>
                  </Text>
                  <StyledInput
                    name="description"
                    mt={1.5}
                    placeholder="Brief description for your profile. URLs are hyperlinked."
                    label="Bio"
                    as={Textarea}
                    isInvalid={errors.description}
                  />
                </Box>
              </div>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default ProfileImage;
