import React, { useEffect, useState } from "react";
import {
  Box,
  Spinner,
  Image,
  Text,
  Button,
  useDisclosure,
  ModalHeader,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  InputGroup,
  Input,
  InputRightElement,
  useToast,
  Flex,
} from "@chakra-ui/react";
import "../../css/ButtonAnimations.css";
import { createThemeApplier } from "../../helpers";
import { Share2, X, Copy } from "lucide-react";
import { handleCopy } from "../../helpers/copyText";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  XIcon,
  WhatsappIcon,
} from "react-share";
// image
import image from "../../pages/images/gmail.png";
import { getProfileImage } from "../../api";
// mySocials svg and png
import Telegram from "./mySocialsSvgs/telegramSvg";
import Facebook from "./mySocialsSvgs/facebookSvg";
import Whatsapp from "./mySocialsSvgs/whatsappSvg";
import Twitterx from "./mySocialsSvgs/twitterxSvg";
import Instagram from "./mySocialsSvgs/instagramSvg";
import Linkedin from "./mySocialsSvgs/linkedinSvg";
import Discord from "./mySocialsSvgs/discord";
import blackCircledEnvelope from "./mySocialsSvgs/blackcircledEnvelope.png";
import whiteCircledEnvelope from "./mySocialsSvgs/whitecircledEnvelope.png";
import CrossButton from "../../components/form/svgs/crossButton";
import footerImage from "../../img/koii_dark.png";
import footerImage2 from "../../img/koii_light.png";

// share links
const ShareComponent = ({ shareLink, size }) => {
  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <FacebookShareButton url={shareLink} style={{ padding: "10px" }}>
        <FacebookIcon
          bgStyle={{ fill: "#3a559f" }}
          size={size}
          round
          className="custom-css-child"
        />
      </FacebookShareButton>

      <TwitterShareButton
        url={shareLink}
        title={"Check out my MOTI.BIO profile at "}
        style={{ padding: "10px" }}
      >
        <XIcon size={size} round className="custom-css-child" />
      </TwitterShareButton>

      <EmailShareButton
        subject={"Check out my MOTI.BIO profile!"}
        body={"Check out my MOTI.BIO profile at  " + shareLink}
        style={{
          padding: "10px",
          border: "1px solid #f2f2f2",
          borderRadius: "50%",
        }}
        openShareDialogOnClick
      >
        <img
          src={image}
          alt="Mail Image"
          style={{
            width: "64px",
            height: "auto",
          }}
          className="custom-css-child-image"
        />
      </EmailShareButton>

      <WhatsappShareButton
        url={shareLink}
        title={"Check out my MOTI.BIO profile at "}
        style={{ padding: "10px" }}
      >
        <WhatsappIcon
          bgStyle={{ fill: "#2ba819" }}
          size={size}
          round
          className="custom-css-child"
        />
      </WhatsappShareButton>
    </Box>
  );
};

function SingleLinktree({
  isLoading,
  isProfileOwner,
  userData,
  noProfileText,
  userPublicKey,
}) {
  // modify the urls
  const modifyUrl = (url) => {
    if (!url) return url;
    let cleanedUrl = url.replace(/\/$/, "").replace(/\?$/, "");

    const baseUrlPattern =
      /^(https?:\/\/)(www\.)?koii\.network\/node(?:[/?#]|$)/;

    if (baseUrlPattern.test(cleanedUrl)) {
      cleanedUrl += cleanedUrl.includes("?")
        ? `&moti=${userData?.linktreeAddress}`
        : `?moti=${userData?.linktreeAddress}`;
    }

    return cleanedUrl;
  };

  useEffect(() => {
    document.title = `${userData?.name} | MOTI.BIO`;
  }, []);

  // need to be change
  const buttonBgColors = {
    Dark: "linear-gradient(94.11deg, hsl(314, 0%, 25%), hsl(214, 0%, 45%),  hsl(314, 0%, 25%))",
    Mint: "#100E1E",
    Gradient:
      "linear-gradient(94.11deg, hsl(214, 100%, 35%), hsl(214, 100%, 55%), hsl(214, 100%, 35%));",
    "Gradient-Two": "#734C3D",
  };

  // const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (userData?.name) document.title = `${userData?.name} | MOTI.BIO`;
    setTimeout(() => {
      createThemeApplier(userData?.theme);
    }, 500);
  }, [userData?.theme, userData?.name]);

  const color = ["Dark", "Gradient"].includes(userData?.theme)
    ? "white"
    : "black";
  const Logo = ["Gradient", "Dark"].includes(userData?.theme)
    ? "/images/DarkThemeLogo.png"
    : "/images/logo.png";
  const toast = useToast();

  const shareLink = `https://www.moti.bio/${userData?.linktreeAddress}`;

  const [getBase64ImagePath, setBase64ImagePath] = useState("");

  useEffect(() => {
    if (
      userData?.koiiImagePath !== undefined &&
      userData?.koiiImagePath !== null &&
      userData?.koiiImagePath !== ""
    ) {
      loadImage(userData?.koiiImagePath);
    }
  }, [userData?.koiiImagePath]);

  async function loadImage(getImage) {
    try {
      const imageData = await getProfileImage(userPublicKey, getImage);
      setBase64ImagePath(imageData);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  }

  // show the hover texts
  const [hoveredIndexDID, setHoveredIndexDID] = useState(null);
  // modal close and open
  const [isOpens, setIsOpen] = useState(false);
  const [modalData, setmodalData] = useState(null);
  const [modalDescription, setModalDescription] = useState(null);
  const [modalButtonLabel, setModalButtonLabel] = useState(null);
  const [modalButtonLink, setModalButtonLink] = useState(null);
  const toggleModal = (data) => {
    const publishedDescription = (data?.meta.publishedDescription || "")
      .replace(/\\u003C/g, "<")
      .replace(/\\u003E/g, ">")
      .replace(/\$username\$/g, `<b>${userData?.linktreeAddress}</b>`);
    const publishedCTAButtonText = data?.meta.publishedCTAButtonText || "";
    const publishedCTAButtonHyperlink = (
      data?.meta.publishedCTAButtonHyperlink || ""
    ).replace(/\$username\$/g, `${userData?.linktreeAddress}`);
    setModalDescription(publishedDescription);
    setmodalData(data);
    setModalButtonLabel(publishedCTAButtonText);
    setModalButtonLink(publishedCTAButtonHyperlink);

    setIsOpen(!isOpens);
  };
  const closeModal = () => {
    setIsOpen(!isOpens);
  };

  return (
    <>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="2rem 2rem 0 2rem"
      >
        {/* moti.bio logo */}
        <Link to="https://moti.bio">
          <img src={Logo} alt="main logo" style={{ height: "40px" }} />
        </Link>
        {/* share button */}
        <Button
          onClick={onOpen}
          _hover={{ color, opacity: "0.9" }}
          display={{ base: "none", md: "flex" }}
          color={color}
          leftIcon={<Share2 color={color} size="20px" />}
          backgroundColor="transparent"
          width="7.8rem"
          height="2.5rem"
          borderRadius="90px"
          border={`1px solid ${color}`}
        >
          Share
        </Button>
        {/* button icon for the share when screen width less thn 744px  */}
        <Box
          cursor="pointer"
          onClick={onOpen}
          display={{ base: "block", md: "none" }}
        >
          <Share2 color={color} size="20px" />
        </Box>
      </Box>

      <Box position="relative" width="100%">
        <Box
          minHeight="82vh"
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyItems="center"
          className="container"
        >
          {isLoading ? (
            <Box
              height="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyItems="center"
            >
              <Spinner height="50px" width="50px" />
            </Box>
          ) : (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              {userData && (
                <>
                  {/* show the user image */}
                  <Image
                    height="12.5rem"
                    width="12.5rem"
                    src={
                      getBase64ImagePath
                        ? getBase64ImagePath
                        : userData?.image
                        ? userData?.image
                        : "/images/DefaultUserProfile.png"
                    }
                    alt={userData?.name}
                    className="user-image"
                  />

                  {/* name of the user */}
                  <Text
                    fontSize="3rem"
                    fontFamily="Poppins"
                    fontWeight="700"
                    lineHeight="3.5rem"
                    width="100%"
                    textAlign={"center"}
                    maxWidth={"750px"}
                    className="user-name"
                  >
                    {userData?.name}
                  </Text>

                  {/* bio of the user */}
                  <Text
                    fontSize="1.5rem"
                    fontFamily="Poppins"
                    fontWeight="500"
                    className="user-desc"
                  >
                    {userData?.description
                      ?.split("\n")
                      .map((line, index, array) => (
                        <React.Fragment key={index}>
                          {line}
                          {index < array.length - 1 && <br />}
                        </React.Fragment>
                      ))}
                  </Text>

                  {/* show mySocials */}
                  <div className="mySocials">
                    {userData?.mySocials?.map((mySocials, index) => (
                      <>
                        {mySocials?.label === "X (Twitter)" &&
                          mySocials?.content !== "" && (
                            <div key={mySocials?.content}>
                              <a
                                href={`https://twitter.com/${
                                  mySocials?.content &&
                                  mySocials.content.startsWith("@")
                                    ? mySocials.content.slice(1)
                                    : mySocials?.content
                                }`}
                                target="_blank"
                              >
                                <Twitterx
                                  customColor={
                                    userData?.theme === "Gradient" ||
                                    userData?.theme === "Dark"
                                      ? "white"
                                      : "black"
                                  }
                                />
                              </a>
                            </div>
                          )}
                        {mySocials?.label === "Instagram" &&
                          mySocials?.content !== "" && (
                            <div key={mySocials?.content}>
                              <a
                                href={`https://www.instagram.com/${
                                  mySocials?.content &&
                                  mySocials.content.startsWith("@")
                                    ? mySocials.content.slice(1)
                                    : mySocials?.content
                                }`}
                                target="_blank"
                              >
                                <Instagram
                                  customColor={
                                    userData?.theme === "Gradient" ||
                                    userData?.theme === "Dark"
                                      ? "white"
                                      : "black"
                                  }
                                />
                              </a>
                            </div>
                          )}
                        {mySocials?.label === "Facebook" &&
                          mySocials?.content !== "" && (
                            <div key={mySocials?.content}>
                              <a href={mySocials?.content} target="_blank">
                                <Facebook
                                  customColor={
                                    userData?.theme === "Gradient" ||
                                    userData?.theme === "Dark"
                                      ? "white"
                                      : "black"
                                  }
                                />
                              </a>
                            </div>
                          )}
                        {mySocials?.label === "Linkedln" &&
                          mySocials?.content !== "" && (
                            <div key={mySocials?.content}>
                              <a href={mySocials?.content} target="_blank">
                                <Linkedin
                                  customColor={
                                    userData?.theme === "Gradient" ||
                                    userData?.theme === "Dark"
                                      ? "white"
                                      : "black"
                                  }
                                />
                              </a>
                            </div>
                          )}
                        {mySocials?.label === "Telegram" &&
                          mySocials?.content !== "" && (
                            <div key={mySocials?.content}>
                              <a
                                href={`https://t.me/${
                                  mySocials?.content &&
                                  mySocials.content.startsWith("@")
                                    ? mySocials.content.slice(1)
                                    : mySocials?.content
                                }`}
                                target="_blank"
                              >
                                <Telegram
                                  customColor={
                                    userData?.theme === "Gradient" ||
                                    userData?.theme === "Dark"
                                      ? "white"
                                      : "black"
                                  }
                                />
                              </a>
                            </div>
                          )}
                        {mySocials?.label === "Whatsapp" &&
                          mySocials?.content !== "" && (
                            <div key={mySocials?.content}>
                              <a
                                href={`https://api.whatsapp.com/send?phone=${
                                  mySocials?.content &&
                                  mySocials.content.startsWith("+")
                                    ? mySocials.content.slice(1)
                                    : mySocials?.content
                                }`}
                                target="_blank"
                              >
                                <Whatsapp
                                  customColor={
                                    userData?.theme === "Gradient" ||
                                    userData?.theme === "Dark"
                                      ? "white"
                                      : "black"
                                  }
                                />
                              </a>
                            </div>
                          )}
                        {mySocials?.label === "Email" &&
                          mySocials?.content !== "" && (
                            <div key={mySocials?.content}>
                              <a
                                href={`mailto:${mySocials?.content}`}
                                target="_blank"
                              >
                                {userData?.theme === "Gradient" ||
                                userData?.theme === "Dark" ? (
                                  <Image
                                    src={whiteCircledEnvelope}
                                    alt={mySocials?.label}
                                  />
                                ) : (
                                  <Image
                                    src={blackCircledEnvelope}
                                    alt={mySocials?.label}
                                  />
                                )}
                              </a>
                            </div>
                          )}
                        {mySocials?.label === "Discord" &&
                          mySocials?.content !== "" && (
                            <div key={mySocials?.content}>
                              <a href={mySocials?.content} target="_blank">
                                <Discord
                                  customColor={
                                    userData?.theme === "Gradient" ||
                                    userData?.theme === "Dark"
                                      ? "white"
                                      : "black"
                                  }
                                />
                              </a>
                            </div>
                          )}
                      </>
                    ))}
                  </div>

                  {/* for the Endorsements */}
                  <div className="endorsements">
                    <Flex flexDirection="row" flexWrap="wrap" w={"100%"}>
                      {userData?.endorsement?.map((endorsement, index) => (
                        <Flex
                          flexDirection="column"
                          key={index}
                          onMouseEnter={() => setHoveredIndexDID(index)}
                          onMouseLeave={() => setHoveredIndexDID(null)}
                        >
                          <Box mb={3} p={3}>
                            <Flex alignItems="center" flexDirection="column">
                              <Box mb={2} position="relative">
                                <div
                                  onClick={() => toggleModal(endorsement)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={
                                      endorsement.meta.image
                                        ? endorsement.meta.image
                                        : endorsement.meta.koiiImage
                                        ? endorsement.meta.koiiImage
                                        : null
                                    }
                                    alt={`${endorsement.meta.image}-endorsement`}
                                    style={{
                                      width: "90px",
                                      height: "90px",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </div>
                                {/* hover text */}
                                {hoveredIndexDID === index && (
                                  <Box
                                    position="absolute"
                                    top="-20px"
                                    bg={"black"}
                                    p={1}
                                    borderRadius="md"
                                    boxShadow="md"
                                    zIndex={10}
                                    textAlign="center"
                                    width="100px"
                                    left="50%"
                                    transform="translateX(-50%)"
                                  >
                                    <Text color="white" fontSize="sm">
                                      {endorsement.meta.hoverText}
                                    </Text>
                                  </Box>
                                )}
                              </Box>
                            </Flex>
                          </Box>
                        </Flex>
                      ))}
                    </Flex>
                  </div>

                  {/* show custom links */}
                  <div className="links">
                    {userData?.links?.map((link, index) => (
                      <div className="link-container" key={link?.redirectUrl}>
                        <a
                          href={modifyUrl(link?.redirectUrl)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Button
                            color={index === 0 ? "white" : "black"}
                            width={{ base: "20rem", md: "29.3rem" }}
                            height="3.6rem"
                            borderRadius="70px"
                            disabled={{
                              background: buttonBgColors[userData?.theme],
                              opacity: "30%",
                            }}
                            _hover={{
                              background:
                                index === 0
                                  ? buttonBgColors[userData?.theme]
                                  : "white",
                              opacity: "0.9",
                            }}
                            mt="15px"
                            background={
                              index === 0
                                ? buttonBgColors[userData?.theme]
                                : "white"
                            }
                          >
                            {link.label}
                          </Button>
                        </a>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {!userData && !isLoading && <p>{noProfileText}</p>}
            </Box>
          )}
        </Box>

        {/* edit button/claim button */}
        <Text
          fontSize="1rem"
          fontFamily="Poppins"
          fontWeight="600"
          color="var(--koi-create-topic)"
          className="footer footer-color"
        >
          {!isProfileOwner && (
            <>
              <a
                href={`https://www.moti.bio/start?ref=${userData.linktreeAddress}`}
                style={{
                  color: "var(--koii-create-topic)",
                  fontSize: "1.2rem",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                }}
              >
                Claim My MOTI.BIO
              </a>
            </>
          )}
          {isProfileOwner && (
            <Link
              to={`/edit-bio/${userData?.linktreeAddress}`}
              style={{
                fontSize: "1.2rem",
                fontFamily: "Poppins",
                fontWeight: "600",
                textDecoration: "none",
                color: "var(--koii-create-topic)",
              }}
            >
              Edit My MOTI.BIO
            </Link>
          )}
        </Text>
      </Box>

      {color === "black" ? (
        <Box as="footer">
          <Image
            src={footerImage}
            alt="Footer Image"
            objectFit="contain"
            boxSize={{ base: "150px", md: "200px" }}
          />
        </Box>
      ) : (
        <Box as="footer">
          <Image
            src={footerImage2}
            alt="Footer Image"
            objectFit="contain"
            boxSize={{ base: "150px", md: "200px" }}
          />
        </Box>
      )}

      {/* model for the ShareComponent */}
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
        backgroundColor="white"
      >
        <ModalOverlay />

        {/* model content */}
        <ModalContent
          maxW="29.5rem"
          width="100%"
          dropShadow="2px 7px 10px 0px #17175340"
          borderRadius="20px"
          bgColor="white"
        >
          {/* model header */}
          <ModalHeader padding="0px">
            <Box
              padding="21px"
              width="100%"
              position="relative"
              justifyContent="space-between"
              justifyItems="center"
              display="flex"
              fontSize="20px"
              lineHeight="24px"
              fontWeight="600"
            >
              <Text
                color="#232121"
                fontFamily="Poppins"
                fontSize="1.2rem"
                fontWeight="600"
              >
                Share
              </Text>
              <Button
                onClick={onClose}
                position="absolute"
                right="16px"
                top="16px"
                cursor="pointer"
                bg="transparent"
                _hover={{
                  bg: "transparent",
                }}
              >
                <X size="20px" />
              </Button>
            </Box>
          </ModalHeader>

          {/* model body */}
          <ModalBody>
            <Box display="flex" justifyContent="space-around"></Box>
            <ShareComponent shareLink={shareLink}></ShareComponent>
          </ModalBody>

          {/* model footer */}
          <ModalFooter>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyItems="center"
              justifyContent="center"
              pb="10px"
            >
              <InputGroup>
                <Input
                  color="black"
                  disabled
                  _disabled={{
                    backgroundColor: "#D5D5D5",
                    opacity: "1",
                    border: "1px solid #D5D5D5",
                  }}
                  defaultValue={"moti.bio/" + userData?.linktreeAddress}
                />
                <InputRightElement
                  onClick={() => handleCopy(userData?.linktreeAddress, toast)}
                  cursor="pointer"
                  ml="10px"
                  mt={1}
                >
                  <Copy color="#494747" />
                </InputRightElement>
              </InputGroup>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* for the Endorsements */}
      <Modal
        isCentered
        isOpen={isOpens}
        onClose={toggleModal}
        size={{ base: "xs", sm: "sm", md: "md" }}
        motionPreset="slideInBottom"
        mx={{ base: 4, md: 0 }}
      >
        <ModalOverlay w={"100%"} h={"100%"} />
        <ModalContent
          maxW="29.5rem"
          width="100%"
          dropShadow="2px 7px 10px 0px #17175340"
          borderRadius="20px"
        >
          {/* header */}
          <ModalHeader padding="0px">
            <Box
              padding="21px"
              width="100%"
              position="relative"
              justifyContent="space-between"
              justifyItems="center"
              display="flex"
              fontSize="20px"
              lineHeight="24px"
              fontWeight="600"
            >
              <Button
                onClick={closeModal}
                position="absolute"
                right="16px"
                top="16px"
                cursor="pointer"
                bg="transparent"
                _hover={{
                  bg: "transparent",
                }}
              >
                <CrossButton fillColor={"black"} />
              </Button>
            </Box>
          </ModalHeader>
          {/* body */}
          <ModalBody>
            <b>
              <Text
                my="10px"
                fontFamily="Poppins"
                color="black"
                textAlign="center"
              >
                {modalData?.meta.title}
              </Text>
              <Text
                my="10px"
                fontFamily="Poppins"
                color="black"
                textAlign="center"
              >
                <em>by {modalData?.meta.issuer_name}</em>
              </Text>
            </b>
            <Box
              my="10px"
              fontFamily="Poppins"
              color="black"
              textAlign="center"
              dangerouslySetInnerHTML={{
                __html: modalDescription,
              }}
            />
            <Text
              my="10px"
              fontFamily="Poppins"
              color="black"
              textAlign="center"
            >
              <b>Obtained at</b>
              <br />
              {(modalData?.meta.endorsementTime &&
                new Date(modalData.meta.endorsementTime * 1000).toLocaleString(
                  undefined,
                  {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )) ||
                "Loading..."}
            </Text>
          </ModalBody>
          {/* footer */}
          <ModalFooter>
            <Button
              as="a"
              href={modalButtonLink}
              target="_blank"
              rel="noopener noreferrer"
              onClick={closeModal}
              _focus={{ outline: "none" }}
              w="100%"
              backgroundColor={"#100E1E"}
              fontFamily="Poppins, sans-serif"
              color={"white"}
              height="3.3rem"
              borderRadius="90px"
              _hover={{ backgroundColor: "#100E1E", opacity: "0.9" }}
              mt={5}
            >
              {modalButtonLabel}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SingleLinktree;
