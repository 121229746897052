import React, { useState, useEffect, useContext } from "react";
import { checkForBadWords } from "../utils";
import LinktreeForm from "../components/form";
import { LogOut } from "lucide-react";
import { Box, Flex, Text, Divider, Button, Image } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import uuid from "react-uuid";
import { getLinktreeWithUsername, setProfileImage } from "../api";
import { useWalletContext } from "../contexts";
import "../css/ButtonAnimations.css";
import { createThemeApplier, getRadioButtonScheme } from "../helpers";
import MasterMagic, { magic } from "../components/modals/magic/MasterMagic";
import { useSelector, useDispatch } from "react-redux";
import { logoutUser, setUser } from "../redux/slice/userSlice";
import LogoutModal from "../components/LogoutModal";
import { useLogoutModalContext } from "../contexts/logoutModalContext";
import { Link, useNavigate } from "react-router-dom";
import footerImage from "../img/koii_dark.png";
import footerImage2 from "../img/koii_light.png";

const CreateLinktree = () => {
  const [image, setImage] = useState(null);
  const [files, setFiles] = useState(null);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [, setImageName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [choosenTheme, setChoosenTheme] = useState("Mint");
  const [choosenLabelTheme, setChoosenLabelTheme] = useState("label-one");
  const [radioColorScheme, setRadioColorScheme] = useState("yellow");
  const [usernameError, setUsernameError] = useState("");
  const [disabled, setDisabled] = useState(true);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const linksGroup = {
    label: "",
    redirectUrl: "",
    key: "",
    isFavorite: false,
  };
  const mySocials = [
    {
      label: "X (Twitter)",
      content: "",
      key: "",
    },
    {
      label: "Instagram",
      content: "",
      key: "",
    },
    {
      label: "Facebook",
      content: "",
      key: "",
    },
    {
      label: "Linkedln",
      content: "",
      key: "",
    },
    {
      label: "Telegram",
      content: "",
      key: "",
    },
    {
      label: "Whatsapp",
      content: "",
      key: "",
    },
    {
      label: "Email",
      content: "",
      key: "",
    },
    {
      label: "Discord",
      content: "",
      key: "",
    },
  ];
  const myWallets = [
    {
      label: "Bitcoin (BTC)",
      address: "",
      signature: "",
      key: "",
    },
    {
      label: "Ethereum (ETH)",
      address: "",
      signature: "",
      key: "",
    },
    {
      label: "Solana (SOL)",
      address: "",
      signature: "",
      key: "",
    },
    {
      label: "Koii/K2 (KOII)",
      address: "",
      signature: "",
      key: "",
    },
    {
      label: "Polygon (MATIC)",
      address: "",
      signature: "",
      key: "",
    },
    {
      label: "TRON (TRX)",
      address: "",
      signature: "",
      key: "",
    },
    {
      label: "Arbitrum (ARB)",
      address: "",
      signature: "",
      key: "",
    },
  ];

  const toast = useToast();
  let { nodeList, magicData: magicData2, setMagicPayload } = useWalletContext();

  // this is for the referralCode
  const [referralCode, setReferralCode] = useState("");
  const [referralUsernameError, setReferralUsernameError] = useState("");

  // get from the cookies
  function getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        const cookieValue = cookie.substring(name.length, cookie.length);
        return cookieValue === "undefined" || cookieValue === "null"
          ? null
          : cookieValue;
      }
    }
    return null;
  }

  useEffect(() => {
    const getObject = window.dataLayer.find(
      (item) => item.event === "referralDetected"
    );
    if (getObject) {
      const referralCode = getObject.referralCode || "";
      setReferralCode(referralCode);
      if (referralCode.trim().length > 0) {
        handleDebouncedReferralChange(referralCode);
      }
    } else {
      const referralValue = getCookie("referral_code");
      if (referralValue === undefined || referralValue === null) {
        setReferralCode("");
      } else {
        setReferralCode(referralValue);
        handleDebouncedReferralChange(referralValue);
      }
    }
  }, []);

  // this is for the theme
  function handleThemeSelection(theme) {
    setChoosenTheme(theme);
    createThemeApplier(theme);
    const colorScheme = getRadioButtonScheme(theme);
    setRadioColorScheme(colorScheme);
  }

  const {
    isOpen: isLogoutModalOpen,
    onClose: closeLogoutModal,
    onOpen: openLogoutModal,
  } = useLogoutModalContext();
  const [magicData, setMagicData] = useState(magicData2);

  // check for the login
  useEffect(() => {
    magic.user.isLoggedIn().then((e) => {
      if (!e) {
        dispatch(logoutUser());
        navigate("/login");
      }
    });
  });

  useEffect(() => {
    document.title = "Claim Your MOTI.BIO";
    setTimeout(() => {
      document.documentElement.setAttribute("data-theme", "light_create");
    }, 100);
  }, []);

  // get the links
  const insertKey = (links) => {
    return links.map((item, index) => {
      return {
        ...item,
        isFavorite: index === 0 ? true : false,
        key: uuid(),
      };
    });
  };

  // get the mySocials
  const insertMySocialsKey = (mySocials) => {
    return mySocials.map((item) => ({
      ...item,
      key: uuid(),
    }));
  };

  // get the myWallets
  const insertMyWalletsKey = (myWallets) => {
    return myWallets.map((item) => ({
      ...item,
      key: item.key && item.key.length > 0 ? item.key : uuid(),
    }));
  };

  useEffect(() => {
    setMagicPayload(null);
  }, []);

  useEffect(() => {
    handleThemeSelection(choosenTheme);
    if (user?.user?.linktree) {
      navigate(`/edit-bio/${user?.user?.linktree?.linktreeAddress}`, {
        replace: true,
      });
      return;
    }

    let publicKey = user?.user?.publicKey;
    setMagicData(publicKey);
    if (!publicKey) {
      toast({
        title: "PLease Login to continue!",
        description: "You'll be re-directed to Login page",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setTimeout(() => {
        navigate("/login");
      }, 3000);
      return;
    }
  }, []);

  const handleSubmitMagic = async (values, actions) => {
    const documentCookies = `; ${document.cookie}`;
    const getCookieValue = (name) => {
      const parts = documentCookies.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
      return null;
    };
    const awsAlbCookie = getCookieValue("AWSALB");
    const awsAlbCorsCookie = getCookieValue("AWSALBCORS");
    const awsMotiSessionCookie = getCookieValue("motisession");
    const cookies = [
      awsAlbCookie ? `AWSALB=${awsAlbCookie}` : "",
      awsAlbCorsCookie ? `AWSALBCORS=${awsAlbCorsCookie}` : "",
      awsMotiSessionCookie ? `motisession=${awsMotiSessionCookie}` : "",
    ]
      .filter(Boolean)
      .join("; ");

    // check the username is already exit or not
    const userData = await getLinktreeWithUsername(
      values.linktreeAddress,
      nodeList
    );
    if (userData?.data?.username) {
      toast({
        title: "This username already exists.",
        description: "Please try another username",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setUsernameError("Username already exists! Please choose another one.");
      setDisabled(true);
      setIsLoading(false);
      return;
    }

    if (referralUsernameError.trim().length > 0) {
      toast({
        title: "Incorrect referral username provided",
        description: "Please try another username",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      setDisabled(true);
      setIsLoading(false);
      return;
    }

    // check the image size
    if (image?.size > 1024 * 1024 * 4) {
      setIsLoading(false);
      return toast({
        title: "Try again",
        description:
          "Your image is too powerful! Please reupload a file below 4MB.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }

    // add links
    values.links = insertKey(values.links, values.linktreeAddress);

    // add the mySocials
    values.mySocials = insertMySocialsKey(values.mySocials);

    // add the myWallets
    values.myWallets = insertMyWalletsKey(values.myWallets);

    let getImagePath;
    if (image) {
      getImagePath = await setProfileImage(
        user?.user?.publicKey,
        image,
        "",
        cookies
      );

      setIsUploadingImage(true);
      uploadToDb(values, image, getImagePath);
    } else {
      uploadToDb(values, image, null);
    }
  };

  function uploadToDb(values, image, getImagePath) {
    const payload = {
      uuid: uuid(),
      linktree: {
        ...values,
        image: image
          ? getImagePath.protocolLink
            ? getImagePath.protocolLink
            : null
          : null,
        koiiImagePath: image
          ? getImagePath.imagepath
            ? getImagePath.imagepath
            : null
          : null,
        background: "",
        ...(referralCode ? { ref: referralCode } : {}),
        theme: choosenTheme,
        choosenLabelTheme: choosenLabelTheme,
      },
      timestamp: Date.now(),
    };

    dispatch(
      setUser({
        publicKey: user?.user?.publicKey,
        email: user?.user?.email,
        ...payload,
      })
    );

    setMagicPayload(payload);
    setIsLoading(false);
  }

  // for the username
  const handleDebouncedChange = (() => {
    let timeoutId;
    return async (value) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        try {
          const userData = await getLinktreeWithUsername(value, nodeList);
          if (userData?.data?.username) {
            setUsernameError(
              "Username already exists! Please choose another one."
            );
            setDisabled(true);
            return false;
          } else {
            setUsernameError(
              value.length > 5 && !checkForBadWords(value)
                ? "This username is not available"
                : ""
            );
            setDisabled(false);
            setIsValidUrl(userData.status);
          }
        } catch (error) {
          console.log(error);
        }
      }, 500);
    };
  })();
  const handleDebouncedReferralChange = (() => {
    let timeoutId;
    return async (value) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        try {
          // this is referral part check for the username
          if (value.trim().length === 0) {
            setReferralUsernameError("");
            return true;
          }
          const userData = await getLinktreeWithUsername(value, nodeList);
          if (!userData?.data?.username) {
            setReferralUsernameError("Incorrect referral username provided");
            setDisabled(true);
            return false;
          } else {
            setDisabled(false);
            setReferralUsernameError("");
          }
        } catch (error) {
          console.log(error);
        }
      }, 300);
    };
  })();
  const handleChangeUserName = async (e, checkFor) => {
    const { value } = e.target;
    if (checkFor === "referralUsername") {
      handleDebouncedReferralChange(value);
      return;
    }
    handleDebouncedChange(value);
  };

  // choose the theme
  const handleLabelSelection = (e) => {
    setChoosenLabelTheme(e);
  };

  const color = ["Gradient", "Dark"].includes(choosenTheme) ? "white" : "black";
  const Logo = ["Gradient", "Dark"].includes(choosenTheme)
    ? "/images/DarkThemeLogo.png"
    : "/images/logo.png";

  return (
    <>
      {/* for the logout button */}
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="2rem 2rem 0 2rem"
      >
        <Link to="https://moti.bio">
          <img src={Logo} alt="main logo" style={{ height: "40px" }} />
        </Link>
        <Button
          _hover={{ color, opacity: "0.9" }}
          color={color}
          onClick={openLogoutModal}
          leftIcon={<LogOut color={color} size="20px" />}
          backgroundColor="transparent"
          width="7.8rem"
          height="2.5rem"
          borderRadius="90px"
          border={`1px solid ${color}`}
        >
          Logout
        </Button>
      </Box>

      {/* create profile  */}
      <>
        <Flex justify="center" align="center" width="100%">
          <Box
            py={{ base: "2rem", md: "5rem" }}
            px={8}
            margin="auto"
            maxWidth={{ base: "100%", md: "800px" }}
            className="createLinktree"
          >
            {/* title of the form */}
            <Flex>
              <Text
                my="5px"
                color="var(--koii-create-topic)"
                fontSize={{ base: "24px", md: "32px" }}
                fontFamily="Poppins"
                fontStyle="normal"
                fontWeight="700"
                lineHeight={{ base: "24px", md: "40px" }}
                textAlign="center"
                width="100%"
              >
                Claim Your MOTI.BIO
              </Text>
            </Flex>

            {/* divider */}
            <Divider color="#C5C5C5" borderWidth="1px" />

            <Text
              fontSize={{ base: "xl", md: "xl" }}
              fontWeight="700"
              my={5}
              fontFamily="Poppins"
              color="var(--koii-create-topic)"
            >
              Profile Link
            </Text>

            {/* user form */}
            {user?.user && (
              <>
                <LinktreeForm
                  // for theme
                  choosenLabelTheme={choosenLabelTheme}
                  choosenTheme={choosenTheme}
                  // group links
                  linksGroup={linksGroup}
                  // mySocials group links
                  mySocials={mySocials}
                  // myWallets group links
                  myWallets={myWallets}
                  // for the image
                  image={image}
                  // image uploading
                  isUploadingImage={isUploadingImage}
                  // set files, image, and imageName
                  setFiles={setFiles}
                  setImage={setImage}
                  setImageName={setImageName}
                  // on change username
                  handleChangeUserName={handleChangeUserName}
                  // error on username
                  usernameError={usernameError}
                  isValidUrl={isValidUrl}
                  disabled={disabled}
                  // label and theme selection
                  handleLabelSelection={handleLabelSelection}
                  handleThemeSelection={handleThemeSelection}
                  // theme color scheme
                  colorScheme={radioColorScheme}
                  // loading button
                  isLoading={isLoading}
                  // referral code
                  referralCode={referralCode}
                  setReferralCode={setReferralCode}
                  referralUsernameError={referralUsernameError}
                  // submit button
                  handleSubmit={handleSubmitMagic}
                  // publish button
                  registerLinkText={"PUBLISH"}
                />
                <MasterMagic />
              </>
            )}
          </Box>
        </Flex>

        {color === "black" ? (
          <Box as="footer">
            <Image
              src={footerImage}
              alt="Footer Image"
              objectFit="contain"
              boxSize={{ base: "150px", md: "200px" }}
            />
          </Box>
        ) : (
          <Box as="footer">
            <Image
              src={footerImage2}
              alt="Footer Image"
              objectFit="contain"
              boxSize={{ base: "150px", md: "200px" }}
            />
          </Box>
        )}
      </>

      <LogoutModal isOpen={isLogoutModalOpen} onClose={closeLogoutModal} />
    </>
  );
};

export default CreateLinktree;
