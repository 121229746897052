import { ethers } from "ethers";
import { errorPopup, successPopup, warningPopup } from "../toastCreatePage";
import bs58 from "bs58";

export async function Bitcoin(
  toast,
  walletAdapter,
  myWallets,
  index,
  setFieldValue
) {
  try {
    const adapter = new walletAdapter();
    await adapter.connect();
    try {
      // wallet verification
      if (!window.phantom?.bitcoin || !window.phantom) {
        errorPopup(
          toast,
          `Phantom is not installed or not enabled. Check the Docs https://phantom.app/`
        );
        return;
      }
      const accounts = await window.phantom?.bitcoin.requestAccounts();
      const p2wpkhAccounts = accounts.filter(
        (account) => account.purpose === "ordinals"
      );
      // get the public key and sign the message
      const nonce = ethers.utils.hexlify(ethers.utils.randomBytes(64)).slice(2);
      const signerAddress = p2wpkhAccounts[0].address;
      const message = `Welcome to MOTI.BIO!\n\nClick to sign in and accept the MOTI.BIO Terms of Service (https://home.moti.bio/terms) and Privacy Policy (https://home.moti.bio/privacy).\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nWallet address:\n${signerAddress}\n\nNonce:\n${nonce}`;
      const getTheTextEncoder = new TextEncoder().encode(message);
      const { signature } = await window.phantom?.bitcoin.signMessage(
        signerAddress,
        getTheTextEncoder
      );
      const encodeSignature = bs58.encode(signature);
      // set the value and show the success message
      successPopup(toast, `${myWallets[index]["label"]} verified`);
      setFieldValue(`myWallets.${index}.address`, signerAddress);
      setFieldValue(`myWallets.${index}.signature`, encodeSignature);
      setFieldValue(`myWallets.${index}.key`, nonce);

      adapter.disconnect();
    } catch (error) {
      // show the error
      setFieldValue(`myWallets.${index}.address`, "");
      setFieldValue(`myWallets.${index}.signature`, "");
      setFieldValue(`myWallets.${index}.key`, "");
      warningPopup(toast, "You Rejected the request in your wallet");
    }
  } catch (error) {
    // show the error
    errorPopup(
      toast,
      `Phantom is not installed or not enabled. Check the Docs https://phantom.app/`
    );
  }
}
