import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWalletContext } from "../../../contexts";
import { Magic } from "magic-sdk";
import { SolanaExtension } from "@magic-ext/solana";
import * as web3 from "@_koi/web3.js";
import { X, Copy, ArrowUpRightFromSquare } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { handleCopy } from "../../../helpers/copyText";
import { useLocation } from "react-router-dom";
import {
  Box,
  Button,
  useToast,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Image,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import bs58 from "bs58";
import { setUser } from "../../../redux/slice/userSlice";
const rpcUrl = "https://testnet.koii.live";

let backend_route;

if (process.env.REACT_APP_DEVELOPMENT) {
  backend_route = process.env["REACT_APP_TASKNET_URL"];
} else {
  backend_route = `${process.env["REACT_APP_TASKNET_URL"]}/task/${process.env["REACT_APP_TASK_ID"]}`;
}

export const magic = new Magic("pk_live_0B806DF108602DBF", {
  extensions: {
    solana: new SolanaExtension({
      rpcUrl,
    }),
  },
});
import { Link } from "react-router-dom";

export default function MasterMagic({ handleSubmit }) {
  const { onClose, onOpen, isOpen } = useDisclosure();
  const { magicPayload, setMagicPayload } = useWalletContext();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  useEffect(() => {
    if (magicPayload?.uuid) {
      location.pathname === "/create-bio"
        ? handleSignMessage()
        : handleUpdateSignMessage();
    }
  }, [magicPayload]);

  const handleUpdateSignMessage = async (data) => {
    try {
      setIsLoading(true);
      data = magicPayload;

      // const metadata = await magic.user.getMetadata();
      const metadata = await magic.user.getInfo();
      const payer = new web3.PublicKey(metadata.publicAddress);

      const messageString = JSON.stringify(data);

      // Use Magic to sign the message
      const signedMessage = await magic.solana.signMessage(
        new TextEncoder().encode(messageString)
      );

      const payload = {
        data,
        publicKey: payer,
        signature: bs58.encode(signedMessage),
        username: magicPayload.linktree.linktreeAddress,
      };

      dispatch(
        setUser({
          publicKey: user?.user?.publicKey,
          email: user?.user?.email,
          ...payload?.data,
        })
      );

      const documentCookies = `; ${document.cookie}`;
      const getCookieValue = (name) => {
        const parts = documentCookies.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(";").shift();
        return null;
      };
      const awsAlbCookie = getCookieValue("AWSALB");
      const awsAlbCorsCookie = getCookieValue("AWSALBCORS");
      const awsMotiSessionCookie = getCookieValue("motisession");
      const awsMotiSessionCookiesig = getCookieValue("motisession.sig");
      const cookies = [
        awsAlbCookie ? `AWSALB=${awsAlbCookie}` : "",
        awsAlbCorsCookie ? `AWSALBCORS=${awsAlbCorsCookie}` : "",
        awsMotiSessionCookie ? `motisession=${awsMotiSessionCookie}` : "",
        awsMotiSessionCookiesig
          ? `motisession.sig=${awsMotiSessionCookiesig}`
          : "",
      ]
        .filter(Boolean)
        .join("; ");

      console.log(cookies);

      setIsLoading(true);
      const response = await fetch(`${backend_route}/linktree`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Cookie: cookies,
        },
        body: JSON.stringify({ payload }),
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `HTTPS error! status: ${response.status}, message: ${errorText}`
        );
      }
      const result = await response.json();
      onOpen();
      setMagicPayload(null);

      if (result?.message) {
        return result;
      }
    } catch (error) {
      console.error("Error in handleSignMessage:", error);
    }
  };

  const handleSignMessage = async (data) => {
    try {
      setIsLoading(true);
      data = magicPayload;

      const metadata = await magic.user.getInfo();
      const payer = new web3.PublicKey(metadata.publicAddress);

      const messageString = JSON.stringify(data);

      // Use Magic to sign the message
      const signedMessage = await magic.solana.signMessage(
        new TextEncoder().encode(messageString)
      );
      const payload = {
        data,
        publicKey: payer,
        signature: bs58.encode(signedMessage),
        username: magicPayload.linktree.linktreeAddress,
      };

      const documentCookies = `; ${document.cookie}`;
      const getCookieValue = (name) => {
        const parts = documentCookies.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(";").shift();
        return null;
      };
      const awsAlbCookie = getCookieValue("AWSALB");
      const awsAlbCorsCookie = getCookieValue("AWSALBCORS");
      const awsMotiSessionCookie = getCookieValue("motisession");
      const cookies = [
        awsAlbCookie ? `AWSALB=${awsAlbCookie}` : "",
        awsAlbCorsCookie ? `AWSALBCORS=${awsAlbCorsCookie}` : "",
        awsMotiSessionCookie ? `motisession=${awsMotiSessionCookie}` : "",
      ]
        .filter(Boolean)
        .join("; ");

      console.log(cookies);

      try {
        setIsLoading(true);
        const response = await fetch(`${backend_route}/linktree`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Accept-Language": "en-US,en;q=0.9",
            Cookie: cookies,
          },
          body: JSON.stringify({ payload }),
        });

        if (response.ok) {
          toast({
            title: "Profile created successfully!",
            position: "top",
            status: "success",
            duration: 3000,
            isClosable: true,
          });

          // Execute onOpen function
          onOpen();
        } else {
          const errorText = await response.text();
          throw new Error(`Failed to create profile: ${errorText}`);
        }
      } catch (error) {
        console.log(`Error:`, error);
        toast({
          title: "Error",
          position: "top",
          description: "An error occurred while claiming your profile",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
        setMagicPayload(null);
      } finally {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error in handleSignMessage:", error);
    }
  };

  const handleClose = () => {
    onClose();
    if (location.pathname === "/create-bio") {
      navigate(`/edit-bio/${user?.user?.linktree?.linktreeAddress}`);
    }
  };

  const handleNavigate = () => {
    let link = document.createElement("a");
    link.href = `/${user?.user?.linktree?.linktreeAddress}`;
    link.target = "_blank";
    link.click();
    link.remove();
  };

  return (
    <Box>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent
          maxW="29.5rem"
          width="100%"
          dropShadow="2px 7px 10px 0px #17175340"
          borderRadius="20px"
          bgColor="white"
        >
          <ModalHeader padding="0px">
            <Box
              padding="21px"
              width="100%"
              position="relative"
              fontSize="20px"
              lineHeight="24px"
              fontWeight="600"
            >
              {/* <p>Login via Magic.link</p> */}
              <Button
                onClick={onClose}
                position="absolute"
                right="16px"
                top="16px"
                cursor="pointer"
                bg="transparent"
                _hover={{
                  bg: "transparent",
                }}
              >
                <X size="20px" />
              </Button>
            </Box>
          </ModalHeader>
          <ModalBody>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Image
                alt="sucess"
                src={
                  location.pathname === "/create-bio"
                    ? "/images/profile_claimed.png"
                    : "/images/profile_update_icon.png"
                }
              />
            </Box>
            <Text
              fontWeight="600"
              fontSize="1.5rem"
              fontFamily="Poppins"
              color="black"
              textAlign="center"
            >
              {location.pathname === "/create-bio"
                ? "Profile Claimed"
                : "Profile Updated"}
            </Text>
            <Text
              fontWeight="400"
              mt="10px"
              fontSize="0.9remrem"
              fontFamily="Poppins"
              color="black"
              textAlign="center"
            >
              {location.pathname === "/create-bio" ? (
                <>
                  Congratulations, you have successfully claimed your unique
                  MOTI.BIO link ✌️
                  <br />
                  <strong>For rewards, join our </strong>
                  <Link
                    href="https://discord.moti.bio"
                    className="llink"
                    target="_blank"
                  >
                    Community Discord
                  </Link>
                  <strong> and verify using your unique URL now!</strong>
                </>
              ) : (
                "Congratulations, you have successfully updated your profile ✌️"
              )}
            </Text>
          </ModalBody>
          <ModalFooter display="flex" flexDirection="column">
            <Box width="100%" pb="10px">
              {/* <Login /> */}
              <InputGroup my="10px">
                <Input
                  color="black"
                  disabled
                  _disabled={{
                    backgroundColor: "#D5D5D5",
                    opacity: "1",
                    border: "1px solid #D5D5D5",
                  }}
                  defaultValue={`moti.bio/${
                    user?.user?.linktree?.linktreeAddress ||
                    magicPayload?.linktree?.linktreeAddress
                  }`}
                />
                <InputRightElement
                  onClick={handleNavigate}
                  cursor="pointer"
                  mr="33px"
                  mt={1}
                >
                  <ArrowUpRightFromSquare color="#494747" />
                </InputRightElement>
                <InputRightElement
                  onClick={() =>
                    handleCopy(
                      user?.user?.linktree?.linktreeAddress ||
                        magicPayload?.linktree?.linktreeAddress,
                      toast
                    )
                  }
                  cursor="pointer"
                  ml="10px"
                  mt={1}
                >
                  <Copy color="#494747" />
                </InputRightElement>
              </InputGroup>
            </Box>
            <Button
              onClick={handleClose}
              fontFamily="Poppins, sans-serif"
              w="100%"
              backgroundColor={"#100E1E"}
              color={"white"}
              height="3.3rem"
              borderRadius="90px"
              _hover={{ backgroundColor: "#100E1E", opacity: "0.9" }}
            >
              Ok
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
