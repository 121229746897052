import React from "react";
import { Box, Text, Stack, Radio, RadioGroup } from "@chakra-ui/react";

const colorThemeStyle = {
  borderRadius: "50%",
  height: { base: "4rem", md: "5.6rem" },
  width: { base: "4rem", md: "5.6rem" },
};

const Wrapper = ({
  children,
  colorScheme,
  value,
  choosenTheme,
  height,
  width,
  borderRadius,
  ...rest
}) => {
  const borderColor = ["Gradient", "Dark"].includes(choosenTheme)
    ? "white"
    : "black";
  return (
    <Radio value={value} colorScheme={colorScheme} size="lg" hidden {...rest}>
      <Box
        border={choosenTheme === value ? `1.5px solid ${borderColor}` : "none"}
        borderRadius={borderRadius}
        height={height}
        width={width}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {children}
      </Box>
    </Radio>
  );
};

export const PersonalizeLinktree = ({
  colorScheme,
  choosenTheme,
  handleThemeSelection,
}) => {
  return (
    <>
      <Text
        fontSize="1.25rem"
        fontFamily="Poppins"
        fontStyle="normal"
        fontWeight="700"
        lineHeight="26px"
        letterSpacing="0.36px"
        mt={5}
        mb={3}
        color="var(--koii-create-topic)"
      >
        Personalize Your Profile
      </Text>

      <Box color="white" width="100%">
        <Box maxW={{ base: "100%" }}>
          <Text
            fontSize="1rem"
            fontFamily="Poppins"
            fontStyle="normal"
            fontWeight="700"
            lineHeight="20px"
            letterSpacing="0.36px"
            color="var(--koii-create-topic)"
            alignItems="center"
          >
            Background color themes
          </Text>
          <Text
            fontSize="12px"
            fontFamily="Sora"
            fontStyle="normal"
            fontWeight={400}
            lineHeight="16px"
            color="#6B6B72"
            alignItems="center"
            mt="10px"
          >
            This will determine your background color, buttons and other graphic
            elements.
          </Text>
        </Box>
        <Box width={{ base: "100%", md: "60%" }} mt={5}>
          <Stack direction={{ base: "column", md: "row" }} width="100%">
            <RadioGroup
              onChange={handleThemeSelection}
              value={choosenTheme}
              width="100%"
            >
              <Stack
                direction="row"
                gap="10px"
                justifyContent="space-between"
                width="100%"
              >
                <Wrapper
                  {...colorThemeStyle}
                  colorScheme={colorScheme}
                  value="Mint"
                  choosenTheme={choosenTheme}
                >
                  <img alt="theme 1" src="/images/ColorTheme1.png" />
                </Wrapper>
                <Wrapper
                  {...colorThemeStyle}
                  choosenTheme={choosenTheme}
                  value="Dark"
                  colorScheme={colorScheme}
                >
                  <img alt="theme 2" src="/images/ColorTheme2.png" />
                </Wrapper>
                <Wrapper
                  {...colorThemeStyle}
                  choosenTheme={choosenTheme}
                  value="Gradient"
                  colorScheme={colorScheme}
                >
                  <img alt="color theme 4" src="/images/ColorTheme3.png" />
                </Wrapper>
                <Wrapper
                  {...colorThemeStyle}
                  choosenTheme={choosenTheme}
                  colorScheme={colorScheme}
                  value="Gradient-Two"
                >
                  <img alt="theme 4" src="/images/ColorTheme4.png" />
                </Wrapper>
              </Stack>
            </RadioGroup>
          </Stack>
        </Box>
      </Box>
    </>
  );
};
