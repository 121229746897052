import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Box,
  Button,
  ModalCloseButton,
  Select,
} from "@chakra-ui/react";

const WalletSelectionModal = ({
  isOpen,
  onClose,
  selectedWallet,
  setSelectedWallet,
  handleWalletVerify,
  selectedWalletIndex,
  myWallets,
}) => {
  let walletName = "";
  if (myWallets && myWallets[selectedWalletIndex]) {
    walletName = myWallets[selectedWalletIndex].label;
  }
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "xs", sm: "sm", md: "md" }}
      motionPreset="slideInBottom"
      mx={{ base: 4, md: 0 }}
    >
      <ModalOverlay w={"100%"} h={"100%"} />
      <ModalContent
        maxW="29.5rem"
        width="100%"
        dropShadow="2px 7px 10px 0px #17175340"
        borderRadius="20px"
      >
        {/* header */}
        <ModalHeader padding="0px">
          <Box
            padding="21px"
            width="100%"
            position="relative"
            justifyContent="space-between"
            justifyItems="center"
            display="flex"
            fontSize="20px"
            lineHeight="24px"
            fontWeight="600"
          >
            Select a Wallet {walletName}
            <ModalCloseButton />
          </Box>
        </ModalHeader>
        {/* body */}
        <ModalBody>
          {walletName === "Ethereum (ETH)" ? (
            <Select
              placeholder="Select wallet"
              value={selectedWallet}
              onChange={(e) => setSelectedWallet(e.target.value)}
            >
              <option value="coinbase">Coinbase</option>
              <option value="phantom">Phantom</option>
              <option value="metaMask">Meta Mask</option>
            </Select>
          ) : walletName === "Bitcoin (BTC)" ? (
            <Select
              placeholder="Select wallet"
              value={selectedWallet}
              onChange={(e) => setSelectedWallet(e.target.value)}
            >
              <option value="phantom">Phantom</option>
            </Select>
          ) : walletName === "Koii/K2 (KOII)" ? (
            <Select
              placeholder="Select wallet"
              value={selectedWallet}
              onChange={(e) => setSelectedWallet(e.target.value)}
            >
              <option value="finnie">Finnie</option>
            </Select>
          ) : (
            <Select
              placeholder="Select wallet"
              value={selectedWallet}
              onChange={(e) => setSelectedWallet(e.target.value)}
            >
              <option value="phantom">Phantom</option>
              <option value="solflare">Solflare</option>
              <option value="coinbase">Coinbase</option>
            </Select>
          )}
        </ModalBody>
        {/* footer */}
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleWalletVerify}>
            Verify Wallet
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WalletSelectionModal;
