import React, { useState, useEffect } from "react";
import { Divider, useToast } from "@chakra-ui/react";
import { Formik } from "formik";
import { array, object, string, mixed, boolean } from "yup";
import "../../css/ButtonAnimations.css";
import { PersonalizeLinktree } from "./personalize-linktree";
import { useSelector } from "react-redux";
import { errorPopup, infoPopup } from "./toastCreatePage";
// for the wallet dropdown
import WalletSelectionModal from "./walletVerifications/customWalletModal";
// for the wallet verification
import { metaMask } from "./walletVerifications/metaMask";
import { Solana } from "./walletVerifications/solana";
import { finnieWallet } from "./walletVerifications/finni";
import { Bitcoin } from "./walletVerifications/bitcoin";
// for the extension
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import { CoinbaseWalletAdapter } from "@solana/wallet-adapter-coinbase";
// components for the forms
import ProfileLink from "./profileLink/profile-link";
import ProfileImage from "./profileImage/profile-image";
import MySocials from "./mySocials/my-socials";
import MyLinks from "./myLinks/my-links";
import BindWallets from "./bindWallets/bind-wallets";
import EmailWalletsAddress from "./email&WalletAddress/email-walletsAddress";
import PublishOrUpdateButton from "./customButton/publish-update-button";
import { buttonBgColors } from ".";
import { getProfileImage } from "../../api";

function LinktreeEditForm({
  choosenLabelTheme,
  choosenTheme,
  linksGroup,
  handleSubmit,
  userData,
  isUploadingImage,
  isLoading,
  handleLabelSelection,
  handleThemeSelection,
  setImage,
  magicPayload,
  image,
  colorScheme,
  setImageChanged,
  getPublicKey,
  myWallets,
  mySocials,
}) {
  const user = useSelector((state) => state.user?.user);

  const toast = useToast();

  const [getBase64ImagePath, setBase64ImagePath] = useState("");
  // for the profile image
  useEffect(() => {
    if (
      userData?.koiiImagePath !== undefined &&
      userData?.koiiImagePath !== null &&
      userData?.koiiImagePath !== ""
    ) {
      loadImage(userData?.koiiImagePath);
    }
  }, [userData?.koiiImagePath]);
  async function loadImage(getImage) {
    try {
      const imageData = await getProfileImage(getPublicKey, getImage);
      setBase64ImagePath(imageData);
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  }

  // for the wallets
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);
  const [selectedWallet, setSelectedWallet] = useState("");
  const [selectedWalletIndex, setSelectedWalletIndex] = useState("");
  const handleClick = async (index, setFieldValue) => {
    const walletLabel = myWallets[index].label;
    setSelectedWalletIndex(index);
    setIsOpen(true);
  };
  const deleteWalletAddress = (index, setFieldValue) => {
    setFieldValue(`myWallets.${index}.address`, "");
    setFieldValue(`myWallets.${index}.signature`, "");
    setFieldValue(`myWallets.${index}.key`, "");
  };
  // for the solana/Ethereum verification
  const handleWalletVerify = async (setFieldValue) => {
    const cryptoName = myWallets[selectedWalletIndex].label;
    switch (selectedWallet) {
      case "phantom":
        if (cryptoName === "Ethereum (ETH)") {
          await metaMask(
            myWallets,
            selectedWalletIndex,
            setFieldValue,
            toast,
            "phantom",
            PhantomWalletAdapter
          );
        } else if (cryptoName === "Solana (SOL)") {
          Solana(
            toast,
            PhantomWalletAdapter,
            myWallets,
            selectedWalletIndex,
            setFieldValue
          );
        } else if (cryptoName === "Bitcoin (BTC)") {
          await Bitcoin(
            toast,
            PhantomWalletAdapter,
            myWallets,
            selectedWalletIndex,
            setFieldValue
          );
        }
        break;
      case "solflare":
        Solana(
          toast,
          SolflareWalletAdapter,
          myWallets,
          selectedWalletIndex,
          setFieldValue
        );
        break;
      case "coinbase":
        if (cryptoName === "Ethereum (ETH)") {
          await metaMask(
            myWallets,
            selectedWalletIndex,
            setFieldValue,
            toast,
            "coinbase",
            CoinbaseWalletAdapter
          );
        } else if (cryptoName === "Solana (SOL)") {
          await Solana(
            toast,
            CoinbaseWalletAdapter,
            myWallets,
            selectedWalletIndex,
            setFieldValue
          );
        }
        break;
      case "metaMask":
        await metaMask(
          myWallets,
          selectedWalletIndex,
          setFieldValue,
          toast,
          "metaMask",
          ""
        );
        break;
      case "finnie":
        await finnieWallet(
          myWallets,
          selectedWalletIndex,
          setFieldValue,
          toast
        );
        break;
      default:
        errorPopup(toast, "Please select a wallet.");
        break;
    }
    handleClose();
  };

  // for merge the fields
  const mergeArrays = (defaultArray, userArray) => {
    const mergedArray = [...defaultArray];
    userArray.forEach((userObj) => {
      const index = mergedArray.findIndex(
        (defaultObj) => defaultObj["label"] === userObj["label"]
      );

      if (index === -1) {
        mergedArray.push(userObj);
      }
    });
    return mergedArray;
  };

  return (
    <Formik
      initialValues={{
        name: userData?.name,
        description: userData?.description,
        image: image
          ? image
          : getBase64ImagePath
          ? getBase64ImagePath
          : userData?.image
          ? userData?.image
          : "/images/DefaultUserProfile.png",
        background: "",
        links: userData?.links,
        linktreeAddress: userData?.linktreeAddress,
        mySocials: mergeArrays(userData?.mySocials, mySocials),
        // mySocials:
        //   typeof userData?.mySocials === "object" && userData.mySocials !== null
        //     ? userData.mySocials
        //     : mySocials,
        myWallets:
          typeof userData?.myWallets === "object" && userData.myWallets !== null
            ? userData.myWallets
            : myWallets,
      }}
      validationSchema={object({
        name: string().required("Name is required"),
        description: string()
          .min(5, "Bio is too short!")
          .max(140, "Bio should be less than 140 characters.")
          .required("A bio is required"),
        linktreeAddress: string()
          .min(5, "Address is too short!")
          .max(200, "Address is too Long")
          .required("An address is required.")
          .matches(
            /^[\w-]+$/,
            "Invalid characters. Only letters, numbers, underscores, and dashes are allowed."
          ),
        image: mixed().nullable().optional(),
        links: array(
          object({
            label: string().required("Link label is required"),
            redirectUrl: string()
              .required("Link URL is required")
              .matches(/^(https?:\/\/)/i, "Enter correct url!"),
            key: string(),
            isFavorite: boolean(),
          })
        )
          .min(1, "At least one link is required!")
          .required("Add a social link"),
        mySocials: array(
          object({
            label: string(),
            content: string().when("label", (label, field) => {
              if (label[0] === "Linkedln") {
                return string().matches(
                  /^(https?:\/\/)?(?:www\.)?linkedin\.com\/(in|company)\/[\w-]+$/i,
                  `Please enter a valid ${label[0]} URL`
                );
              } else if (label[0] == "Facebook") {
                return string().matches(
                  /^(https?:\/\/)?(www\.)?facebook\.com\/.+$/i,
                  `Please enter a valid ${label[0]} URL`
                );
              } else if (label[0] == "Email") {
                return string().matches(
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i,
                  `Please enter a valid ${label[0]} address`
                );
              } else if (label[0] === "Whatsapp") {
                return string().matches(
                  /^\+[\d]+[\s]*[\d]+$/,
                  `Please enter a valid Phone number.`
                );
              } else if (label[0] === "Telegram") {
                return string().matches(
                  /@[a-zA-Z0-9_.]{1,30}/,
                  `Please enter a valid ${label[0]} username.`
                );
              } else if (label[0] === "Discord") {
                return string().matches(
                  /^https:\/\/discord(app)?\.com\/users\/(\d{17,19})$/,
                  `Please enter a valid ${label[0]} url.`
                );
              } else {
                return string().matches(
                  /@[a-zA-Z0-9_.]{1,30}/,
                  `Please enter a valid ${label[0]} handle.`
                );
              }
            }),
          })
        ),
        myWallets: array(
          object({
            label: string(),
            address: string(),
            key: string(),
          })
        ),
      })}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleSubmit,
        isValid,
        errors,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit}>
          <ProfileLink
            setFieldValue={setFieldValue}
            isValidUrl={""}
            handleChangeUserName={""}
            usernameError={""}
            errors={errors}
            values={values}
            page={false}
          />

          {/* hr line */}
          <Divider my={5} color="#C5C5C5" borderWidth="1px" />

          {/* profile picture and name and description */}
          <ProfileImage
            errors={errors}
            image={image}
            choosenTheme={choosenTheme}
            setImage={setImage}
            page={false}
            getBase64ImagePath={getBase64ImagePath}
            setImageChanged={setImageChanged}
            userData={userData}
          />

          {/* title of your Social & Contacts links */}
          <MySocials
            setFieldValue={setFieldValue}
            errors={errors}
            values={values}
          />

          {/* hr line */}
          <Divider my={5} color="#C5C5C5" borderWidth="1px" />

          <MyLinks
            setFieldValue={setFieldValue}
            errors={errors}
            values={values}
            linksGroup={linksGroup}
          />

          {/* hr line */}
          <Divider my={5} color="#C5C5C5" borderWidth="1px" />

          {/* Bind Wallets */}
          <BindWallets
            setFieldValue={setFieldValue}
            values={values}
            myWallets={myWallets}
            handleClick={handleClick}
            deleteWalletAddress={deleteWalletAddress}
            buttonBgColors={buttonBgColors}
            choosenTheme={choosenTheme}
          />

          {/* hr line */}
          <Divider my={5} color="#C5C5C5" borderWidth="1px" />

          {/* color themes */}
          <PersonalizeLinktree
            colorScheme={colorScheme}
            choosenTheme={choosenTheme}
            handleThemeSelection={handleThemeSelection}
            handleLabelSelection={handleLabelSelection}
            values={values}
            choosenLabelTheme={choosenLabelTheme}
          />

          {/* update button */}
          <PublishOrUpdateButton
            buttonBgColors={buttonBgColors}
            choosenTheme={choosenTheme}
            magicPayload={magicPayload}
            isUploadingImage={isUploadingImage}
            isSubmitting={isSubmitting}
            isLoading={isLoading}
            buttonText={"UPDATE"}
          />

          {/* email and the wallet address */}
          <EmailWalletsAddress user={user} />

          {/* custom verification modal */}
          <WalletSelectionModal
            isOpen={isOpen}
            onClose={handleClose}
            selectedWallet={selectedWallet}
            setSelectedWallet={setSelectedWallet}
            handleWalletVerify={() => handleWalletVerify(setFieldValue)}
            selectedWalletIndex={selectedWalletIndex}
            myWallets={myWallets}
          />
        </form>
      )}
    </Formik>
  );
}

export default LinktreeEditForm;
