const toastOptions = {
  position: "top",
  duration: 4000,
  isClosable: true,
};

export const successPopup = (toast, title) => {
  toast({
    ...toastOptions,
    title: title,
    status: "success",
  });
};

export const errorPopup = (toast, title) => {
  toast({
    ...toastOptions,
    title: title,
    status: "error",
  });
};

export const warningPopup = (toast, title) => {
  toast({
    ...toastOptions,
    title: title,
    status: "warning",
  });
};

export const infoPopup = (toast, title) => {
  toast({
    ...toastOptions,
    title: title,
    status: "info",
  });
};

// add here more toast custom setting
