import React from "react";

const Discord = ({ customColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="5 10 60 60"
      width="65px"
      height="65px"
      fill="none"
      stroke={customColor}
      stroke-width="2"
    >
      <path d="M14.55 40.17c3.53 2.67 8.86 5.46 17.45 5.46s13.92-2.79 17.45-5.46"></path>
      <path d="M48 22.18c-4.31-2.2-8.57-4.26-16-4.26S20.33 20 16 22.18"></path>
      <path d="m27.32 18.23-1.87-3.68a22.75 22.75 0 0 0-10.9 4.36S9 27 8 42.91c5.63 6.49 14.18 6.54 14.18 6.54L25.5 45"></path>
      <path d="m38.5 45 3.32 4.42s8.55-.02 14.18-6.51c-1-15.9-6.55-24-6.55-24s-5-3.92-10.9-4.36l-1.82 3.69"></path>
      <ellipse cx="24.91" cy="34.18" rx="3.82" ry="4.36"></ellipse>
      <ellipse cx="39.09" cy="34.18" rx="3.82" ry="4.36"></ellipse>
    </svg>
  );
};

export default Discord;
