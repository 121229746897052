import React from "react";

const AddButton = ({ fillColor }) => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9436 7.60258C13.6083 7.60258 7.66655 13.5443 7.66655 20.8797C7.66655 28.215 13.6083 34.1567 20.9436 34.1567C28.2789 34.1567 34.2207 28.215 34.2207 20.8797C34.2207 13.5443 28.2789 7.60258 20.9436 7.60258ZM5.92529 20.8797C5.92529 12.5827 12.6466 5.86133 20.9436 5.86133C29.2406 5.86133 35.9619 12.5827 35.9619 20.8797C35.9619 29.1766 29.2406 35.898 20.9436 35.898C12.6466 35.898 5.92529 29.1766 5.92529 20.8797Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9644 21.07C12.9644 20.3968 13.5101 19.8511 14.1832 19.8511H27.678C28.3511 19.8511 28.8968 20.3968 28.8968 21.07C28.8968 21.7431 28.3511 22.2888 27.678 22.2888H14.1832C13.5101 22.2888 12.9644 21.7431 12.9644 21.07Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9586 13.0757C21.6318 13.0757 22.1775 13.6214 22.1775 14.2946V27.7893C22.1775 28.4625 21.6318 29.0082 20.9586 29.0082C20.2855 29.0082 19.7397 28.4625 19.7397 27.7893V14.2946C19.7397 13.6214 20.2855 13.0757 20.9586 13.0757Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default AddButton;
