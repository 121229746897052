import { TASK_ADDRESS } from "../config";

let backend_route;

if (process.env.REACT_APP_DEVELOPMENT) {
  backend_route = process.env["REACT_APP_TASKNET_URL"];
} else {
  backend_route = `${process.env["REACT_APP_TASKNET_URL"]}/task/${process.env["REACT_APP_TASK_ID"]}`;
}

export const truncateAddress = (address) => {
  const firstSlice = address.slice(0, 6);
  const lastSlice = address.slice(38);

  return `${firstSlice}...${lastSlice}`;
};

export const fetchData = async (publicKey) => {
  let nodeList = await getNodeList();

  for (let i = 0; i < nodeList.length; i++) {
    try {
      const response = await fetch(
        `${nodeList[i]}/task/${TASK_ADDRESS}/linktree/get/${publicKey}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      const responseData = await response.json();

      // if (response.data && response.data.data) {
      //   const userData = response.data.data.linktree;
      //   return userData;
      // }

      if (responseData && responseData.data) {
        const userData = responseData.data.linktree;
        return userData;
      }
    } catch (error) {
      return "Error";
    }
  }
  return "Error";
};

export const getNodeList = async () => {
  let nodeList = [];
  const fallbackNodes = [`${backend_route}/${TASK_ADDRESS}`];

  nodeList = fallbackNodes;
  return nodeList;
};

export function themeApplier(userTheme) {
  switch (userTheme) {
    case "Gradient":
      document.documentElement.setAttribute("data-theme", "gradient");
      break;
    case "Mint":
      document.documentElement.setAttribute("data-theme", "gradient_two");
      break;
    case "Dark":
      document.documentElement.setAttribute("data-theme", "dark");
      break;
    case "Gradient-Two":
      document.documentElement.setAttribute("data-theme", "gradient_two");
      break;
    default:
      break;
  }
}

export function createThemeApplier(theme) {
  switch (theme) {
    case "Dark":
      document.documentElement.setAttribute("data-theme", "dark_create");
      break;
    case "Mint":
      document.documentElement.setAttribute("data-theme", "light_create");
      break;
    case "Gradient":
      document.documentElement.setAttribute("data-theme", "gradient_create");
      break;
    case "Gradient-Two":
      document.documentElement.setAttribute(
        "data-theme",
        "gradient_two_create"
      );
      break;
    default:
      document.documentElement.setAttribute("data-theme", "light_create");
      break;
  }
}

export function getRadioButtonScheme(theme) {
  if (theme === "Dark") {
    return "yellow";
  } else if (theme === "Gradient") {
    return "teal";
  } else if (theme === "Gradient-Two") {
    return "yellow";
  } else {
    return "purple";
  }
}
