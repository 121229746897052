import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Divider,
  Spinner,
} from "@chakra-ui/react";
import "../../css/ButtonAnimations.css";
import { useSelector } from "react-redux";
import { getEndorsements } from "../../api";
import { Oval } from "react-loader-spinner";
import { getRadioButtonScheme } from "../../helpers";
import AddButton from "./svgs/addSvg";
import CrossButton from "./svgs/crossButton";
import SubtractButton from "./svgs/subtractSvg";
import { buttonBgColors } from ".";

// for the button color based on the theme
const customButtonBgColors = {
  Dark: "white",
  Mint: "#100E1E",
  Gradient: "white",
  "Gradient-Two": "#100E1E",
};

function Endorsements({
  choosenTheme,
  handleSubmit,
  userData,
  isLoading,
  getPublicKey,
  nodeList,
  magicPayload,
}) {
  const user = useSelector((state) => state.user?.user);

  // save it from the db and filter out if use has already in the DIDs (do with respect to the endorsementId)
  const [saveEndorsements, setEndorsements] = useState([]);
  const [saveListEndorsements, setListEndorsements] = useState([]);
  const [saveListEndorsementsDIDs, setEndorsementsDIDs] = useState([]);
  useEffect(() => {
    if (nodeList.length === 0) return;
    const fetchEndorsements = async () => {
      try {
        const endorsements = await getEndorsements(getPublicKey, nodeList);
        // sort here
        if (endorsements.length > 0) {
          endorsements.sort((a, b) => a.meta.sort - b.meta.sort);
        }
        setEndorsements(Array.isArray(endorsements) ? endorsements : []);

        if (user.linktree.endorsement && user.linktree.endorsement.length > 0) {
          const endorsementIds = user.linktree.endorsement.map(
            (endorsement) => endorsement.endorsementId
          );

          // when it dont match with the DIDs endorsementId get only those endorsement
          const filteredEndorsementsForAdd = endorsements.filter(
            (endorsement) => !endorsementIds.includes(endorsement.endorsementId)
          );

          // sort here
          if (filteredEndorsementsForAdd.length > 0) {
            filteredEndorsementsForAdd.sort(
              (a, b) => a.meta.sort - b.meta.sort
            );
          }

          // add to make sure user can add it
          setListEndorsements(
            Array.isArray(filteredEndorsementsForAdd)
              ? filteredEndorsementsForAdd
              : []
          );

          // when it do match with the DIDs endorsementId get only those endorsement
          const filteredEndorsementsForDID = endorsements.filter(
            (endorsement) => endorsementIds.includes(endorsement.endorsementId)
          );

          setEndorsementsDIDs(
            Array.isArray(filteredEndorsementsForDID)
              ? filteredEndorsementsForDID
              : []
          );
        } else {
          setListEndorsements(Array.isArray(endorsements) ? endorsements : []);
          setEndorsementsDIDs([]);
        }
      } catch (error) {
        console.error("Error fetching endorsements:", error);
        setListEndorsements([]);
        setEndorsementsDIDs([]);
      }
    };

    fetchEndorsements();
  }, [getPublicKey, nodeList.length, user]);

  // show the hover texts
  const [hoveredIndexPending, setHoveredIndexPending] = useState(null);
  const [hoveredIndexDID, setHoveredIndexDID] = useState(null);

  // modal close and open
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setmodalData] = useState(null);
  const [modalDescription, setModalDescription] = useState(null);
  const toggleModal = (data) => {
    const publishedDescription = (data?.meta.publishedDescription || "")
      .replace(/\\u003C/g, "<")
      .replace(/\\u003E/g, ">")
      .replace(/\$username\$/g, `<b>${user.linktree.linktreeAddress}</b>`);

    setModalDescription(publishedDescription);
    setmodalData(data);

    setIsOpen(!isOpen);
  };
  const closeModal = () => {
    setIsOpen(!isOpen);
  };

  // save the DIDs
  const handleAddEndorsementId = (newEndorsement) => {
    setEndorsementsDIDs((prevEndorsement) => [
      ...prevEndorsement,
      newEndorsement,
    ]);
  };

  // remove from the DID
  const handleSubtractEndorsementId = (newEndorsement) => {
    setEndorsementsDIDs((prevEndorsements) =>
      prevEndorsements.filter(
        (endorsement) =>
          endorsement.endorsementId !== newEndorsement.endorsementId
      )
    );
  };

  // check the length
  const [prevLength, setPrevLength] = useState(saveListEndorsementsDIDs.length);

  useEffect(() => {
    // check and update setPrevLength
    if (saveListEndorsementsDIDs.length === prevLength) return;
    setPrevLength(saveListEndorsementsDIDs.length);

    // filter both pending and your endorsement
    const endorsementIds = saveListEndorsementsDIDs.map(
      (endorsement) => endorsement.endorsementId
    );

    // for the endorsement
    const filteredEndorsementsForAdd = saveEndorsements.filter(
      (endorsement) => !endorsementIds.includes(endorsement.endorsementId)
    );
    // sort here
    if (filteredEndorsementsForAdd.length > 0) {
      filteredEndorsementsForAdd.sort((a, b) => a.meta.sort - b.meta.sort);
    }
    setListEndorsements(
      Array.isArray(filteredEndorsementsForAdd)
        ? filteredEndorsementsForAdd
        : []
    );

    // for the DID
    const filteredEndorsementsForDID = saveEndorsements.filter((endorsement) =>
      endorsementIds.includes(endorsement.endorsementId)
    );
    setEndorsementsDIDs(
      Array.isArray(filteredEndorsementsForDID)
        ? filteredEndorsementsForDID
        : []
    );
  }, [
    prevLength,
    saveListEndorsementsDIDs,
    saveEndorsements,
    setListEndorsements,
    setEndorsementsDIDs,
  ]);

  return (
    <>
      {/* for your endorsements */}
      <Box>
        {saveListEndorsementsDIDs === null ? (
          <Box
            my="20px"
            mx="auto"
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Oval
              height={40}
              width={40}
              color={getRadioButtonScheme(userData?.theme)}
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor={getRadioButtonScheme(userData?.theme)}
              strokeWidth={2}
              strokeWidthSecondary={2}
              colorScheme={getRadioButtonScheme(userData?.theme)}
            />
          </Box>
        ) : saveListEndorsementsDIDs.length === 0 ? (
          <Text
            fontSize="0.8rem"
            fontWeight="500"
            mb={1}
            fontFamily="Poppins"
            color="#6B6B72"
          >
            You have no endorsements in this section.
          </Text>
        ) : (
          <Flex
            flexDirection="row"
            flexWrap="wrap"
            w={"100%"}
            textAlign={{ base: "center", md: "left" }}
            mx={{ base: "auto", md: "0px" }}
          >
            {saveListEndorsementsDIDs.map((endorsement, index) => (
              <Flex
                flexDirection="column"
                key={index}
                mx={{ base: "auto", md: "0px" }}
                onMouseEnter={() => setHoveredIndexDID(index)}
                onMouseLeave={() => setHoveredIndexDID(null)}
              >
                <Box mb={3} p={3}>
                  <Flex alignItems="center" flexDirection="column">
                    <Box mb={2} position="relative">
                      <div
                        onClick={() => toggleModal(endorsement)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={
                            endorsement.meta.image
                              ? endorsement.meta.image
                              : endorsement.meta.koiiImage
                              ? endorsement.meta.koiiImage
                              : null
                          }
                          alt={`${endorsement.meta.image}-endorsement`}
                          style={{
                            width: "90px",
                            height: "90px",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      {/* hover text */}
                      {hoveredIndexDID === index && (
                        <Box
                          position="absolute"
                          top="-20px"
                          bg={"black"}
                          p={1}
                          borderRadius="md"
                          boxShadow="md"
                          zIndex={10}
                          textAlign="center"
                          width="100px"
                          left="50%"
                          transform="translateX(-50%)"
                        >
                          <Text color="white" fontSize="sm">
                            {endorsement.meta.hoverText}
                          </Text>
                        </Box>
                      )}
                    </Box>
                    <Box mb={2}>
                      <Text color="var(--koii-create-topic)">
                        {endorsement.meta.title}
                      </Text>
                    </Box>
                    <Box>
                      <Button
                        border="none"
                        cursor="pointer"
                        type="submit"
                        alignSelf="center"
                        backgroundColor="transparent !important"
                        onClick={() => handleSubtractEndorsementId(endorsement)}
                      >
                        <SubtractButton
                          fillColor={customButtonBgColors[choosenTheme]}
                        />
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            ))}
          </Flex>
        )}
      </Box>

      {/* for pending endorsements */}
      <Box>
        <Box
          w={{ base: "80%", md: "100%" }}
          mx={{ base: "auto", md: "0px" }}
          my={4}
        >
          <Text
            fontSize={{ base: "xl", md: "25px" }}
            textAlign={{ base: "center", md: "left" }}
            fontWeight={"700"}
            fontFamily="Poppins"
            lineHeight="32.5px"
            letterSpacing={"-2%"}
            align={"center"}
            color="var(--koii-create-topic)"
          >
            Endorsements Bank
          </Text>
          <Divider
            color="var(--koii-create-topic)"
            borderWidth="0.5px"
            w={{ base: "70%", md: "60%" }}
            mx={{ base: "auto", md: "0px" }}
            my={2}
          />
          <Text
            fontSize="1rem"
            fontWeight="700"
            fontFamily="Poppins"
            color="var(--koii-create-topic)"
          >
            Endorsements in this section are hidden from public view.
          </Text>
        </Box>
        {saveListEndorsements === null ? (
          <Box
            my="20px"
            mx="auto"
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Oval
              height={40}
              width={40}
              color={getRadioButtonScheme(userData?.theme)}
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor={getRadioButtonScheme(userData?.theme)}
              strokeWidth={2}
              strokeWidthSecondary={2}
              colorScheme={getRadioButtonScheme(userData?.theme)}
            />
          </Box>
        ) : saveListEndorsements.length === 0 ? (
          <Text
            fontSize="0.8rem"
            fontWeight="500"
            mb={1}
            fontFamily="Poppins"
            color="#6B6B72"
          >
            You have no endorsements in this section.
          </Text>
        ) : (
          <Flex
            flexDirection="row"
            flexWrap="wrap"
            w={"100%"}
            textAlign={{ base: "center", md: "left" }}
            mx={{ base: "auto", md: "0px" }}
          >
            {saveListEndorsements.map((endorsement, index) => (
              <Flex
                flexDirection="column"
                key={index}
                mx={{ base: "auto", md: "0px" }}
                onMouseEnter={() => setHoveredIndexPending(index)}
                onMouseLeave={() => setHoveredIndexPending(null)}
              >
                <Box mb={3} p={3}>
                  <Flex alignItems="center" flexDirection="column">
                    <Box mb={2} position="relative">
                      <div
                        onClick={() => toggleModal(endorsement)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={
                            endorsement.meta.image
                              ? endorsement.meta.image
                              : endorsement.meta.koiiImage
                              ? endorsement.meta.koiiImage
                              : null
                          }
                          alt={`${endorsement.meta.image}-endorsement`}
                          style={{
                            width: "90px",
                            height: "90px",
                            borderRadius: "50%",
                            filter: "grayscale(100%)",
                          }}
                        />
                      </div>
                      {hoveredIndexPending === index && (
                        <Box
                          position="absolute"
                          top="-20px"
                          bg={"black"}
                          p={1}
                          borderRadius="md"
                          boxShadow="md"
                          zIndex={10}
                          textAlign="center"
                          width="100px"
                          left="50%"
                          transform="translateX(-50%)"
                        >
                          <Text color="white" fontSize="sm">
                            {endorsement.meta.hoverText}
                          </Text>
                        </Box>
                      )}
                    </Box>
                    <Box mb={2}>
                      <Text color="var(--koii-create-topic)">
                        {endorsement.meta.title}
                      </Text>
                    </Box>
                    <Box>
                      <Button
                        border="none"
                        cursor="pointer"
                        type="submit"
                        alignSelf="center"
                        backgroundColor="transparent !important"
                        onClick={() => handleAddEndorsementId(endorsement)}
                      >
                        <AddButton
                          fillColor={customButtonBgColors[choosenTheme]}
                        />
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            ))}
          </Flex>
        )}
      </Box>

      {/* update to DID */}
      <Flex w="100%" alignItems="center">
        <Button
          w="full"
          rounded="full"
          color={"white"}
          background={buttonBgColors[choosenTheme]}
          _hover={
            !isLoading &&
            saveListEndorsementsDIDs.length !== 0 && {
              background: buttonBgColors[choosenTheme],
              opacity: "0.9",
            }
          }
          height="4.125rem"
          mx="auto"
          mt={10}
          type="submit"
          alignSelf="center"
          isDisabled={!!magicPayload || isLoading}
          onClick={() => {
            handleSubmit({
              name: userData?.name,
              description: userData?.description,
              image: userData?.image
                ? userData?.image
                : "/images/DefaultUserProfile.png",
              background: "",
              links: userData?.links,
              linktreeAddress: userData?.linktreeAddress,
              mySocials:
                typeof userData?.mySocials === "object" &&
                userData.mySocials !== null
                  ? userData.mySocials
                  : mySocials,
              myWallets:
                typeof userData?.myWallets === "object" &&
                userData.myWallets !== null
                  ? userData.myWallets
                  : myWallets,
              endorsement: saveListEndorsementsDIDs,
            });
          }}
        >
          {!!magicPayload || isLoading ? <Spinner /> : "UPDATE"}
        </Button>
      </Flex>

      {/* modal popup */}
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={toggleModal}
        size={{ base: "xs", sm: "sm", md: "md" }}
        motionPreset="slideInBottom"
        mx={{ base: 4, md: 0 }}
      >
        <ModalOverlay w={"100%"} h={"100%"} />
        <ModalContent
          maxW="29.5rem"
          width="100%"
          dropShadow="2px 7px 10px 0px #17175340"
          borderRadius="20px"
        >
          {/* header */}
          <ModalHeader padding="0px">
            <Box
              padding="21px"
              width="100%"
              position="relative"
              justifyContent="space-between"
              justifyItems="center"
              display="flex"
              fontSize="20px"
              lineHeight="24px"
              fontWeight="600"
            >
              <Button
                onClick={closeModal}
                position="absolute"
                right="16px"
                top="16px"
                cursor="pointer"
                bg="transparent"
                _hover={{
                  bg: "transparent",
                }}
              >
                <CrossButton fillColor={"black"} />
              </Button>
            </Box>
          </ModalHeader>
          {/* body */}
          <ModalBody>
            <b>
              <Text
                my="10px"
                fontFamily="Poppins"
                color="black"
                textAlign="center"
              >
                {modalData?.meta.title}
              </Text>
              <Text
                my="10px"
                fontFamily="Poppins"
                color="black"
                textAlign="center"
              >
                <em>by {modalData?.meta.issuer_name}</em>
              </Text>
            </b>
            <Box
              my="10px"
              fontFamily="Poppins"
              color="black"
              textAlign="center"
              dangerouslySetInnerHTML={{
                __html: modalDescription,
              }}
            />
            <Text
              my="10px"
              fontFamily="Poppins"
              color="black"
              textAlign="center"
            >
              <b>Obtained at</b>
              <br />
              {(modalData?.meta.endorsementTime &&
                new Date(modalData.meta.endorsementTime * 1000).toLocaleString(
                  undefined,
                  {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )) ||
                "Loading..."}
            </Text>
          </ModalBody>
          {/* footer */}
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Endorsements;
