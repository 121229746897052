import React from "react";

const SubtractButton = ({ fillColor }) => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8796 7.60258C13.5443 7.60258 7.60258 13.5443 7.60258 20.8797C7.60258 28.215 13.5443 34.1567 20.8796 34.1567C28.215 34.1567 34.1567 28.215 34.1567 20.8797C34.1567 13.5443 28.215 7.60258 20.8796 7.60258ZM5.86133 20.8797C5.86133 12.5827 12.5826 5.86133 20.8796 5.86133C29.1766 5.86133 35.898 12.5827 35.898 20.8797C35.898 29.1766 29.1766 35.898 20.8796 35.898C12.5826 35.898 5.86133 29.1766 5.86133 20.8797Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9004 21.07C12.9004 20.3968 13.4461 19.8511 14.1193 19.8511H27.614C28.2872 19.8511 28.8329 20.3968 28.8329 21.07C28.8329 21.7431 28.2872 22.2888 27.614 22.2888H14.1193C13.4461 22.2888 12.9004 21.7431 12.9004 21.07Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default SubtractButton;
