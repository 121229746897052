// For the my Links
import React, { useState } from "react";
import { Box, Flex, Input, Text, Spacer } from "@chakra-ui/react";
import { Field, FieldArray } from "formik";
import { DeleteIcon } from "@chakra-ui/icons";
import { CheckCircle2, XCircle } from "lucide-react";

const BindWallets = ({
  setFieldValue,
  values,
  myWallets,
  handleClick,
  deleteWalletAddress,
  buttonBgColors,
  choosenTheme,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const hideBoxLabels = ["Polygon (MATIC)", "TRON (TRX)", "Arbitrum (ARB)"];

  return (
    <>
      <Text
        fontSize={{ base: "xl", md: "xl" }}
        fontWeight="700"
        my={5}
        fontFamily="Poppins"
        color="var(--koii-create-topic)"
      >
        Bind Your Wallet (Not Displayed on MOTI.BIO Profile)
      </Text>
      <Text
        fontSize="1rem"
        fontWeight="700"
        fontFamily="Poppins"
        color="var(--koii-create-topic)"
      >
        Sign with Wallet App to verify your ownership.
      </Text>
      <Text
        fontSize="0.8rem"
        fontWeight="500"
        fontFamily="Poppins"
        color="#6B6B72"
      >
        Your wallets public keys are stored publicly in the MOTI DID Protocol
      </Text>
      {/* show the Bind Wallets */}
      <FieldArray name="myWallets">
        {({ push, remove }) => (
          <div>
            {values.myWallets.map(
              (_, index) =>
                !hideBoxLabels.includes(_.label) && (
                  <Box key={index}>
                    <Box mt={6} width="100%">
                      <Flex
                        width="100%"
                        flexDirection={{ base: "column", md: "row" }}
                        key={index + 1}
                        mt={2}
                        gap="20px"
                        alignItems={{ base: "end", md: "center" }}
                      >
                        {/* label for the mySocials */}
                        <Box w={{ base: "100%", md: "40%" }}>
                          <Box w="100%">
                            <Text color="var(--koii-create-topic)" width="100%">
                              {_.label}
                            </Text>
                          </Box>
                        </Box>

                        <Spacer />

                        <Box
                          w={{ base: "100%", md: "58%" }}
                          mt={{ base: "0px", md: "0px" }}
                        >
                          <Box w={"100%"}>
                            <Box display="flex" alignItems="center" gap="10px">
                              <Box position="relative" width="100%">
                                {/* input */}
                                <Field
                                  background="var(--koii-input-background-color)"
                                  placeholder="Bind Wallet"
                                  errorBorderColor="red.500"
                                  style={{
                                    borderRadius: "12px",
                                    color: "var(--koii-create-topic)",
                                    paddingRight: "40px",
                                  }}
                                  name={`myWallets.${index}.address`}
                                  label="Wallet Address"
                                  as={Input}
                                  border="1px solid var(--koii-input-border-color)"
                                  width="100%"
                                  onClick={() =>
                                    handleClick(index, setFieldValue)
                                  }
                                  readOnly={true}
                                />

                                {_?.address &&
                                _.signature &&
                                _?.signature.length > 0 ? (
                                  <span
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                    onClick={() =>
                                      handleClick(index, setFieldValue)
                                    }
                                  >
                                    <CheckCircle2
                                      fill="#00BA00"
                                      color="white"
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        zIndex: "1000",
                                      }}
                                    />
                                    {hoveredIndex === index && (
                                      <span
                                        style={{
                                          position: "absolute",
                                          right: "5px",
                                          top: "-10px",
                                          transform: "translateY(-50%)",
                                          cursor: "pointer",
                                          padding: "5px",
                                          color: "white",
                                          fontSize: "small",
                                          backgroundColor: `${buttonBgColors[choosenTheme]}`,
                                        }}
                                      >
                                        Verified
                                      </span>
                                    )}
                                  </span>
                                ) : _?.address ? (
                                  <span
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                    onClick={() =>
                                      handleClick(index, setFieldValue)
                                    }
                                  >
                                    <XCircle
                                      fill="red"
                                      color="white"
                                      style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                      }}
                                    />
                                    {hoveredIndex === index && (
                                      <span
                                        style={{
                                          position: "absolute",
                                          right: "5px",
                                          top: "-10px",
                                          transform: "translateY(-50%)",
                                          cursor: "pointer",
                                          padding: "5px",
                                          color: "white",
                                          fontSize: "small",
                                          backgroundColor: `${buttonBgColors[choosenTheme]}`,
                                        }}
                                      >
                                        Not Verified
                                      </span>
                                    )}
                                  </span>
                                ) : null}
                              </Box>

                              {/* for the delete button */}
                              {_?.address && _?.address.length > 0 && (
                                <>
                                  <Box display={{ base: "flex", md: "none" }}>
                                    <DeleteIcon
                                      onClick={() =>
                                        deleteWalletAddress(
                                          index,
                                          setFieldValue
                                        )
                                      }
                                      style={{
                                        color: "#A6A6A6",
                                        fontSize: "1.3rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Box>
                                  <Box display={{ base: "none", md: "flex" }}>
                                    <DeleteIcon
                                      onClick={() =>
                                        deleteWalletAddress(
                                          index,
                                          setFieldValue
                                        )
                                      }
                                      style={{
                                        color: "#A6A6A6",
                                        fontSize: "1.4rem",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Box>
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                )
            )}
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default BindWallets;
