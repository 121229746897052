// For the username
import React from "react";
import {
  Box,
  Input,
  Text,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { ErrorMessage, Field } from "formik";
import { CheckCircle2, Copy } from "lucide-react";
import { successPopup } from "../toastCreatePage";

const ProfileLink = ({
  setFieldValue,
  isValidUrl,
  handleChangeUserName,
  usernameError,
  errors,
  values,
  page,
}) => {
  const toast = useToast();

  const handleCopy = (text) => {
    navigator.clipboard.writeText(`https://moti.bio/${text}`);
    successPopup(toast, "Link Copied");
  };

  return (
    <Box width="100%">
      {/* main heading */}
      <Text
        fontSize="0.9rem"
        fontWeight="500"
        fontFamily="Poppins"
        color="var(--koii-create-topic)"
      >
        Choose Your Unique MOTI.BIO URL{" "}
        <span style={{ color: "#FD6E6B" }}>*</span>
      </Text>

      {/* username */}
      <Box my={2} width={{ base: "100%" }}>
        <Field
          name="linktreeAddress"
          border="1px solid var(--koii-input-border-color)"
        >
          {({ field, form: { setFieldValue } }) => (
            <>
              {!page ? (
                <>
                  {/* for the edit page */}
                  <InputGroup>
                    <Input
                      errorBorderColor="red.500"
                      disabled
                      value={`moti.bio/${values.linktreeAddress}`}
                      _disabled={{ color: "var(--koii-create-topic)" }}
                      onChange={async (e) => {
                        setFieldValue("linktreeAddress", e.target.value);
                      }}
                      isInvalid={errors && !!errors.linktreeAddress}
                      style={{
                        backgroundColor: "var(--koii-input-background-color)",
                        fontFamily: "Poppins",
                        paddingLeft: "0 !important",
                        color: "var(--koii-create-topic)",
                      }}
                      border="1px solid var(--koii-input-border-color)"
                      type="text"
                      placeholder="yourname"
                    />
                    {!errors.linktreeAddress && field.value && (
                      <InputRightElement
                        onClick={() => handleCopy(values.linktreeAddress)}
                        cursor="pointer"
                        ml="10px"
                        mt={1}
                      >
                        <Copy style={{ color: "var(--koii-create-topic)" }} />
                      </InputRightElement>
                    )}
                  </InputGroup>
                </>
              ) : (
                <>
                  {/* for the create page */}
                  <InputGroup>
                    <InputLeftAddon
                      {...field}
                      name="linktreeAddress"
                      backgroundColor="var(--koii-input-background-color)"
                      color="var(--koii-create-topic)"
                      height="3rem"
                      border={
                        errors.linktreeAddress
                          ? "2px solid #E53E3E"
                          : "1px solid var(--koii-input-border-color)"
                      }
                    >
                      moti.bio/
                    </InputLeftAddon>
                    <Input
                      errorBorderColor="red.500"
                      onChange={async (e) => {
                        setFieldValue("linktreeAddress", e.target.value);
                      }}
                      onKeyUp={(e) => {
                        handleChangeUserName(e, "username");
                      }}
                      isInvalid={errors && !!errors.linktreeAddress}
                      style={{
                        backgroundColor: "var(--koii-input-background-color)",
                        fontFamily: "Poppins",
                        paddingLeft: "0 !important",
                        color: "var(--koii-create-topic)",
                      }}
                      border="1px solid var(--koii-input-border-color)"
                      type="text"
                      placeholder="yourname"
                      height="3rem"
                    />
                    {!errors.linktreeAddress &&
                      field.value &&
                      isValidUrl &&
                      !usernameError && (
                        <InputRightElement mt={1}>
                          <CheckCircle2 fill="#00BA00" color="white" />
                        </InputRightElement>
                      )}
                  </InputGroup>
                  <Text
                    fontFamily="Poppins"
                    mt={0}
                    fontWeight="500"
                    className="error"
                  >
                    <ErrorMessage name="linktreeAddress" />
                    {errors.linktreeAddress}
                  </Text>
                  {!!usernameError && (
                    <Text
                      fontFamily="Poppins"
                      mt={0}
                      fontWeight="500"
                      className="error"
                    >
                      {usernameError}
                    </Text>
                  )}
                </>
              )}
            </>
          )}
        </Field>
      </Box>

      {/* text below username */}
      <Text
        fontSize="0.8rem"
        fontWeight="500"
        mb={1}
        fontFamily="Poppins"
        color="#6B6B72"
      >
        This URL will be soulbound to your Decentralised Identity (DID). By
        claiming this URL, you will not be able to change it again.{" "}
        <strong>
          <em>This is case-sensitive.</em>
        </strong>
        {!page && (
          <>
            <br />
            <strong>
              For rewards, join our{" "}
              <a href="https://discord.moti.bio" target="_blank">
                <u>Community Discord</u>
              </a>{" "}
              and verify using your unique URL now!
            </strong>
          </>
        )}
      </Text>
    </Box>
  );
};

export default ProfileLink;
