import { ethers } from "ethers";
import { errorPopup, successPopup, warningPopup } from "../toastCreatePage";
import { CoinbaseWalletSDK } from "@coinbase/wallet-sdk";
import bs58 from "bs58";

export async function metaMask(
  myWallets,
  index,
  setFieldValue,
  toast,
  cryptoName,
  walletAdapter
) {
  if (cryptoName === "phantom") {
    try {
      const adapter = new walletAdapter();
      await adapter.connect();
      try {
        // wallet verification
        if (!window.phantom?.ethereum || !window.phantom?.ethereum.isPhantom) {
          errorPopup(
            toast,
            `Phantom is not installed or not enabled. Check the Docs https://phantom.app/`
          );
          return;
        }

        const accounts = await window.phantom?.ethereum.request({
          method: "eth_requestAccounts",
        });

        const signerAddress = accounts[0];
        const nonce = ethers.utils
          .hexlify(ethers.utils.randomBytes(32))
          .slice(2);
        const message = `Welcome to MOTI.BIO!\n\nClick to sign in and accept the MOTI.BIO Terms of Service (https://home.moti.bio/terms) and Privacy Policy (https://home.moti.bio/privacy).\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nWallet address:\n${signerAddress}\n\nNonce:\n${nonce}`;
        const signature = await window.phantom?.ethereum.request({
          method: "personal_sign",
          params: [message, signerAddress],
        });

        // set the value and show the success message
        successPopup(toast, `${myWallets[index]["label"]} verified`);
        setFieldValue(`myWallets.${index}.address`, signerAddress);
        setFieldValue(`myWallets.${index}.signature`, signature);
        setFieldValue(`myWallets.${index}.key`, nonce);

        adapter.disconnect();
      } catch (error) {
        // show the error
        setFieldValue(`myWallets.${index}.address`, "");
        setFieldValue(`myWallets.${index}.signature`, "");
        setFieldValue(`myWallets.${index}.key`, "");
        warningPopup(toast, "You Rejected the request in your wallet");
      }
    } catch (error) {
      // show the error
      errorPopup(
        toast,
        `Phantom is not installed or not enabled. Check the Docs https://phantom.app/`
      );
    }
  } else if (cryptoName === "coinbase") {
    const coinbaseWallet = new CoinbaseWalletSDK({
      appName: "MOTI.BIO",
    });
    const provider = coinbaseWallet.makeWeb3Provider();
    const accounts = await provider.request({
      method: "eth_requestAccounts",
    });
    const nonce = ethers.utils.hexlify(ethers.utils.randomBytes(32)).slice(2);
    const signerAddress = accounts[0];
    const message = `Welcome to MOTI.BIO!\n\nClick to sign in and accept the MOTI.BIO Terms of Service (https://home.moti.bio/terms) and Privacy Policy (https://home.moti.bio/privacy).\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nWallet address:\n${signerAddress}\n\nNonce:\n${nonce}`;
    try {
      const signature = await provider.request({
        method: "personal_sign",
        params: [message, signerAddress],
      });
      successPopup(toast, `${myWallets[index]["label"]} verified`);
      setFieldValue(`myWallets.${index}.address`, signerAddress);
      setFieldValue(`myWallets.${index}.signature`, signature);
      setFieldValue(`myWallets.${index}.key`, nonce);
    } catch (error) {
      setFieldValue(`myWallets.${index}.address`, "");
      setFieldValue(`myWallets.${index}.signature`, "");
      setFieldValue(`myWallets.${index}.key`, "");
      warningPopup(toast, "You Rejected the request in your wallet");
    }
  } else if (cryptoName === "metaMask") {
    // Check if MetaMask is installed and enabled
    if (!window.ethereum || !window.ethereum.isMetaMask) {
      errorPopup(
        toast,
        `MetaMask is not installed or not enabled. Check the Docs https://metamask.io/download/`
      );
      return;
    }
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const nonce = ethers.utils.hexlify(ethers.utils.randomBytes(32)).slice(2);
      ethereum
        .request({ method: "eth_requestAccounts" })
        .then(async (accounts) => {
          const signerAddress = accounts[0];
          const message = `Welcome to MOTI.BIO!\n\nClick to sign in and accept the MOTI.BIO Terms of Service (https://home.moti.bio/terms) and Privacy Policy (https://home.moti.bio/privacy).\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nWallet address:\n${signerAddress}\n\nNonce:\n${nonce}`;
          try {
            const signature = await signer.signMessage(message);
            successPopup(toast, `${myWallets[index]["label"]} verified`);
            setFieldValue(`myWallets.${index}.address`, signerAddress);
            setFieldValue(`myWallets.${index}.signature`, signature);
            setFieldValue(`myWallets.${index}.key`, nonce);
          } catch (error) {
            setFieldValue(`myWallets.${index}.address`, "");
            setFieldValue(`myWallets.${index}.signature`, "");
            setFieldValue(`myWallets.${index}.key`, "");
            warningPopup(toast, "You Rejected the request in your wallet");
          }
        })
        .catch((error) => {
          console.error("Error getting signer address:", error);
        });
    } catch (error) {
      console.log("Error:", error);
      errorPopup(toast, "An error occurred. Please try again.");
    }
  }
}
