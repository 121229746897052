// For the my socials
import React from "react";
import { Button, Spinner } from "@chakra-ui/react";
import "../../../css/ButtonAnimations.css";

const PublishOrUpdateButton = ({
  buttonBgColors,
  choosenTheme,
  magicPayload,
  isUploadingImage,
  isSubmitting,
  isLoading,
  buttonText,
}) => {
  return (
    <Button
      w="full"
      rounded="full"
      color={"white"}
      background={buttonBgColors[choosenTheme]}
      _disabled={{
        background: buttonBgColors[choosenTheme],
        opacity: "30%",
      }}
      _hover={{
        background: buttonBgColors[choosenTheme],
        opacity: "0.9",
      }}
      height="4.125rem"
      mx="auto"
      mt={10}
      type="submit"
      isLoading={!!magicPayload || isUploadingImage || isSubmitting}
      isDisabled={!!magicPayload || isUploadingImage || isSubmitting}
      alignSelf="center"
    >
      <> {isLoading ? <Spinner /> : buttonText}</>
    </Button>
  );
};

export default PublishOrUpdateButton;
