// For the my socials
import React from "react";
import { Box, Flex, Input, Text, Spacer } from "@chakra-ui/react";
import { ErrorMessage, Field, FieldArray } from "formik";
import "../../../css/ButtonAnimations.css";

const MySocials = ({ setFieldValue, errors, values }) => {
  const mySocialsPlaceholder = [
    {
      placeholder: "@YourTwitterHandle",
    },
    {
      placeholder: "@YourInstagramHandle",
    },
    {
      placeholder: "https://www.facebook.com/YourFacebookURL",
    },
    {
      placeholder: "https://www.linkedin.com/in/YourLinkedinURL",
    },
    {
      placeholder: "@YourTelegramUsername",
    },
    {
      placeholder: "+AreaCode&PhoneNumber",
    },
    {
      placeholder: "YourEmail@YourDomain.com",
    },
    {
      placeholder: "https://discordapp.com/users/your_user_id",
    },
  ];
  return (
    <>
      {/* title of your Social & Contacts links */}
      <Text
        fontSize={{ base: "xl", md: "xl" }}
        fontWeight="700"
        my={5}
        fontFamily="Poppins"
        color="var(--koii-create-topic)"
      >
        Add Your Socials & Contacts
      </Text>
      <Text
        fontSize="0.8rem"
        fontWeight="500"
        fontFamily="Poppins"
        color="#6B6B72"
      >
        These will display in a standalone section. (Only entered items will be
        displayed)
      </Text>

      <FieldArray name="mySocials">
        {({ push, remove }) => (
          <div>
            {values.mySocials.map((elem, index) => (
              <Box key={index}>
                <Box mt={6} width="100%">
                  <Flex
                    width="100%"
                    flexDirection={{ base: "column", md: "row" }}
                    key={index + 1}
                    mt={2}
                    gap="20px"
                    alignItems={{ base: "end", md: "center" }}
                  >
                    {/* label for the mySocials */}
                    <Box w={{ base: "100%", md: "40%" }}>
                      <Box w="100%">
                        <Text color="var(--koii-create-topic)" width="100%">
                          {elem.label}
                        </Text>
                      </Box>
                    </Box>

                    <Spacer />

                    <Box
                      w={{ base: "100%", md: "58%" }}
                      mt={{ base: "0px", md: "0px" }}
                    >
                      <Box w={"100%"}>
                        {/* add mySocials values */}
                        <Box display="flex" alignItems="center" gap="10px">
                          <Field
                            background="var(--koii-input-background-color)"
                            placeholder={
                              mySocialsPlaceholder[index].placeholder
                            }
                            errorBorderColor="red.500"
                            style={{
                              borderRadius: "12px",
                              color: "var(--koii-create-topic)",
                            }}
                            isInvalid={
                              errors.mySocials &&
                              !!errors.mySocials.index?.content
                            }
                            name={`mySocials.${index}.content`}
                            label="Link URL"
                            as={Input}
                            border="1px solid var(--koii-input-border-color)"
                            width="100%"
                            onFocus={() => {
                              const currentValue =
                                values.mySocials[index].content;

                              const placeholder =
                                mySocialsPlaceholder[index].placeholder;

                              if (!currentValue) {
                                if (placeholder.startsWith("@")) {
                                  setFieldValue(
                                    `mySocials.${index}.content`,
                                    `@`,
                                    false
                                  );
                                }

                                if (placeholder.startsWith("+")) {
                                  setFieldValue(
                                    `mySocials.${index}.content`,
                                    "+",
                                    false
                                  );
                                }

                                if (
                                  placeholder.startsWith(
                                    "https://discordapp.com/users/your_user_id"
                                  )
                                ) {
                                  setFieldValue(
                                    `mySocials.${index}.content`,
                                    "https://discordapp.com/users/",
                                    false
                                  );
                                }
                              }
                            }}
                            onChange={(e) => {
                              let newValue = e.target.value;
                              const placeholder =
                                mySocialsPlaceholder[index].placeholder;

                              if (placeholder.startsWith("@")) {
                                newValue = newValue.replace(/[@+]/g, "");
                                newValue = `@${newValue}`;
                              } else if (placeholder.startsWith("+")) {
                                newValue = newValue.replace(/[@+]/g, "");
                                newValue = `+${newValue}`;
                              }

                              let trimValue = newValue.trim();

                              if (
                                trimValue === "@" ||
                                trimValue === "+" ||
                                trimValue === "https://discordapp.com/users/"
                              ) {
                                setFieldValue(
                                  `mySocials.${index}.content`,
                                  "",
                                  false
                                );
                                return;
                              }

                              setFieldValue(
                                `mySocials.${index}.content`,
                                trimValue,
                                false
                              );
                            }}
                          />
                        </Box>

                        {/* show the error message */}
                        <Box
                          display={{ base: "block", md: "none" }}
                          mt="0px"
                          w={{ base: "100%" }}
                        >
                          <Text className="error">
                            <ErrorMessage name={`mySocials.${index}.content`} />
                          </Text>
                        </Box>
                        <Box
                          display={{ base: "none", md: "block" }}
                          mt="0px"
                          w={{ base: "100%" }}
                        >
                          <Text className="error">
                            <ErrorMessage name={`mySocials.${index}.content`} />
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                </Box>
              </Box>
            ))}
          </div>
        )}
      </FieldArray>
    </>
  );
};

export default MySocials;
